










import Vue from 'vue';

export default Vue.extend({
  name: 'IconChat',

  props: {
    existsChat: Boolean,
    existsUnread: Boolean,
  },
});
