import dayjs from 'dayjs';
import _ from 'lodash';
import type { TimeDataItem } from '@/store/modules/ui/attendance';

// 時刻表示を勤怠履歴カレンダー用の「前」「翌」の文字列を取得する
const getPrefixAttendanceTime = (
  targetDate: string,
  punchData: TimeDataItem,
  atdBorderTime: string
) => {
  if (!punchData || !(punchData.ctime || punchData.time) || punchData.status === -1) {
    return '';
  }

  // targetDate と atdBorderTime を使って referenceDateTime を作成
  const referenceDateTime = dayjs(`${targetDate} ${atdBorderTime}`, 'YYYY-MM-DD HH:mm');

  // targetDateTime を dayjs オブジェクトに変換
  let targetDateTime = '';
  if (!_.isEmpty(punchData.ctime) && !_.isUndefined(punchData.ctime)) {
    targetDateTime = punchData.ctime;
  } else if (!_.isEmpty(punchData.time)) {
    targetDateTime = punchData.time;
  } else {
    return '';
  }
  const targetDateTimeDayjs = dayjs(targetDateTime, 'YYYY-MM-DD HH:mm:ss');

  // referenceDateTime の前日と翌日、翌々日を取得
  const referenceDateTimeDayBefore = dayjs(referenceDateTime).subtract(1, 'day');
  const referenceDateTimeNext = dayjs(referenceDateTime).add(1, 'day');
  const referenceDateTimeNext2 = dayjs(referenceDateTime).add(2, 'day');

  // targetDateTime が referenceDateTimeDayBefore以降 かつ referenceDateTimeより前 の場合、'前' を返す
  if (
    targetDateTimeDayjs.isSameOrAfter(referenceDateTimeDayBefore) &&
    targetDateTimeDayjs.isBefore(referenceDateTime)
  ) {
    return '前';
  }

  // targetDateTime が referenceDateTimeNext以降 かつ referenceDateTimeNext2より前 の場合、'翌' を返す
  if (
    targetDateTimeDayjs.isSameOrAfter(referenceDateTimeNext) &&
    targetDateTimeDayjs.isBefore(referenceDateTimeNext2)
  ) {
    return '翌';
  }

  // それ以外は '' （空）を返す
  return '';
};

export default getPrefixAttendanceTime;
