




















































































































import Vue from 'vue';
import EditProfilePopup from '@/components/specific/EditProfilePopup.vue';
import EditStatusPopup from '@/components/specific/EditStatusPopup.vue';
import UserStatus from '@/components/specific/UserStatus.vue';
import { MESSAGE_CLIPBOARD_COPY_SUCCESS, MESSAGE_CLIPBOARD_COPY_ERROR } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIRelationshipMapper } from '@/store/modules/ui/relationship';
import type { PartialUserAttributes } from '@/store/modules/domain/auth';
import type { PartialMember } from '@/store/modules/ui/relationship';

const UserService = ServiceFactory.get('user');

type Agent = {
  deviceId: string;
  installId: string;
};

export default Vue.extend({
  name: 'ProfilePopup',

  components: {
    EditProfilePopup,
    EditStatusPopup,
    UserStatus,
  },

  data(): {
    initialAttributes: Readonly<PartialUserAttributes>;
    attributes: PartialUserAttributes;
    showedDialog: {
      [key: string]: boolean;
    };
    emailAddressToolTipFlg: boolean;
  } {
    return {
      attributes: {},
      emailAddressToolTipFlg: true,
      initialAttributes: {
        device: '',
        email: '',
        image: '',
        phoneNo: '',
        place: '',
        timezone: '',
        voiceXs: [],
      },
      showedDialog: {
        emotionEdit: false,
        profileEdit: false,
      },
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['profileUserId', 'showedProfilePopup', 'userList']),
    ...UIRelationshipMapper.mapState(['relationship']),

    agents(): Agent[] {
      const { userId } = this.userAttributes;
      const user = this._.find(this.userList, { userId });

      if (this._.isUndefined(user)) {
        return [];
      }

      const agents = this._.get(user, 'agents', []) as Agent[];

      return agents;
    },

    departmentNames(): string {
      return this._.get(this.attributes, 'departments[0].departmentName', '');
    },

    extensions(): string {
      return this._.get(this.attributes, 'voiceXs[0].phoneNo', '');
    },

    me(): PartialMember {
      const { userId } = this.userAttributes;
      return this._.find(this.relationship, { userId }) as PartialMember;
    },

    // 自分か
    myself(): boolean {
      return this.userAttributes.userId === this.profileUserId;
    },

    popupHeaderText(): string {
      return this.myself ? 'マイプロフィール' : 'ユーザープロフィール';
    },
  },

  watch: {
    showedProfilePopup: {
      handler() {
        if (this.showedProfilePopup) {
          this.refresh();
        }
      },
    },

    userAttributes: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },

  methods: {
    ...UICommonMapper.mapActions(['setShowedUserMenu', 'setMessage', 'clearProfileUserId']),

    agentLabelClass(agent: Agent): string {
      return !this._.isEmpty(agent.deviceId) ? 'agent-configured' : 'agent-unset';
    },

    agentText(agent: Agent): string {
      return !this._.isEmpty(agent.deviceId) ? agent.deviceId : agent.installId;
    },

    closePopup() {
      this.showedDialog.emotionEdit = false;
      this.attributes = this._.cloneDeep(this.initialAttributes);
      this.$emit('click-close');
      // this.$nextTick().then(() => {
      //   this.setShowedUserMenu({ showed: true });
      // });
    },

    onCloseEditStatusPopup() {
      this.attributes.emotion = this.userAttributes.emotion;
      this.showedDialog.emotionEdit = false;
    },

    openEmotionEdit() {
      this.showedDialog.emotionEdit = true;
    },

    openProfileEdit() {
      this.showedDialog.profileEdit = true;
      this.showedDialog.emotionEdit = false;
    },

    async refresh() {
      const self = this;
      self.attributes = self._.cloneDeep(self.initialAttributes);

      if (self._.isEmpty(self.profileUserId)) {
        return;
      }

      try {
        const responseUser = await UserService.getUser(
          self.userAttributes.workspaceId,
          self.profileUserId
        );

        self.attributes = self._.assignIn({}, self.attributes, responseUser);

        // メールアドレスwidth取得しポップアップを出すか判断
        this.$nextTick().then(() => {
          const element = document.getElementById('email-address-width');
          if (element) {
            element.style.display = 'inline-block';
            const elementWidth = element.clientWidth;
            if (elementWidth > 295) {
              self.emailAddressToolTipFlg = false;
            }
          }
        });
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }

      this.showedDialog.profileEdit = false;
    },

    setMessageClipboardCopyError(event: Event) {
      this.$$log.debug('setMessageClipboardCopyError', 'event:', event);

      this.setMessage({
        color: 'error',
        text: MESSAGE_CLIPBOARD_COPY_ERROR,
      });
    },

    setMessageClipboardCopySuccess(event: Event) {
      this.$$log.debug('setMessageClipboardCopySuccess', 'event:', event);

      this.setMessage({ color: 'success', text: MESSAGE_CLIPBOARD_COPY_SUCCESS });
    },

    textForClipboardCopy(agent: Agent) {
      return this.agentText(agent);
    },
  },
});
