















import Vue from 'vue';

export default Vue.extend({
  name: 'QuickChatSendResult',

  props: {
    result: {
      type: Boolean,
      required: true,
    },
  },
});
