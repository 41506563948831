
































































































































































































































































































































































































import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import ReconnectingWebSocket from 'reconnecting-websocket';
import Vue from 'vue';
// import TheHeaderButtonInfo from '@/components/generic/TheHeaderButtonInfo.vue';
import { VListItemContent, VListItemTitle } from 'vuetify/lib';
import Alert from '@/components/generic/Alert.vue';
import PopupBackgroundLayer from '@/components/generic/PopupBackgroundLayer.vue';
import TheHelpButton from '@/components/generic/TheHelpButton.vue';
import TheNavigationMenu from '@/components/generic/TheNavigationMenu.vue';
import AttendanceBookPopup from '@/components/specific/AttendanceBookPopup.vue';
import AttendanceEditPopup from '@/components/specific/AttendanceEditPopup.vue';
import GreetingPopup from '@/components/specific/GreetingPopup.vue';
import IconBell from '@/components/specific/IconBell.vue';
import IconChat from '@/components/specific/IconChat.vue';
import IconUserClock from '@/components/specific/IconUserClock.vue';
import MemberCircle from '@/components/specific/MemberCircle.vue';
import MemberPopup from '@/components/specific/MemberPopup.vue';
import ProfilePopup from '@/components/specific/ProfilePopup.vue';
import QuickChatListPopup from '@/components/specific/QuickChatListPopup.vue';
import QuickChatNotification from '@/components/specific/QuickChatNotification.vue';
import QuickChatPopup from '@/components/specific/QuickChatPopup.vue';
import QuickChatReplyPopup from '@/components/specific/QuickChatReplyPopup.vue';
import QuickChatSendResult from '@/components/specific/QuickChatSendResult.vue';
import WebPushRecommendPopup from '@/components/specific/WebPushRecommendPopup.vue';
import WorkspacePopup from '@/components/specific/WorkspacePopup.vue';
import { TITLE_WEB_PUSH_RECEIVED, MESSAGE_QUICK_CHAT_RECEIVED } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import settings from '@/settings';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UIChatMapper } from '@/store/modules/ui/chat';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIMemberFilterMapper, InitialSearchConditions } from '@/store/modules/ui/memberFilter';
import { UINotificationMapper } from '@/store/modules/ui/notification';
import { UIRelationshipMapper } from '@/store/modules/ui/relationship';
import type { PopupMember } from '@/components/specific/MemberPopup.vue';
import type { UserAttributes } from '@/store/modules/domain/auth';
import type { ReceivedChat } from '@/store/modules/ui/chat';
import type { MemberEvent, PartialMember, Relationship } from '@/store/modules/ui/relationship';

interface ExternalLinkItem {
  annotation: string;
  detail: string;
  icon: string;
  linkButtonText: string;
  linked: boolean;
  name: string;
  unlinkButtonText: string;
}

const ChatService = ServiceFactory.get('chat');
const GoogleService = ServiceFactory.get('google');
const PhraseService = ServiceFactory.get('phrase');
const RelationshipService = ServiceFactory.get('relationship');
const SearchService = ServiceFactory.get('search');
const UserService = ServiceFactory.get('user');
const WebPushService = ServiceFactory.get('webPush');

export default Vue.extend({
  name: 'TheContainerDefault',

  components: {
    AttendanceBookPopup,
    AttendanceEditPopup,
    GreetingPopup,
    IconBell,
    IconChat,
    IconUserClock,
    MemberCircle,
    MemberPopup,
    PopupBackgroundLayer,
    ProfilePopup,
    QuickChatListPopup,
    QuickChatPopup,
    QuickChatReplyPopup,
    // TheHeaderButtonInfo,
    TheHelpButton,
    TheNavigationMenu,
    VListItemContent,
    VListItemTitle,
    WebPushRecommendPopup,
    WorkspacePopup,
  },

  data(): {
    currentExternalLinkItem: ExternalLinkItem;
    deployTargetName: string;
    desiredExternalLinkItemCount: number;
    existsChat: boolean;
    existsUnreadChat: boolean;
    externalLinkItems: Record<string, ExternalLinkItem>;
    intervalID: number | undefined;
    memberPopupReady: boolean;
    menuOpened: boolean;
    notificationEnabled: boolean;
    online: boolean;
    popupMember: PopupMember;
    settingTab: number;
    showedDialog: {
      [key: string]: boolean;
    };
    voiceXState: {
      colorRotate: number;
      linkedIds: string[];
    };
    websocketMessage: boolean;
    ws: ReconnectingWebSocket | undefined;
  } {
    return {
      currentExternalLinkItem: {
        annotation: '',
        detail: '',
        icon: '',
        linkButtonText: '連携する',
        linked: false,
        name: '',
        unlinkButtonText: '連携解除する',
      },
      deployTargetName: settings.deployTargetName,
      desiredExternalLinkItemCount: 3,
      existsChat: false,
      existsUnreadChat: false,
      externalLinkItems: {
        google: {
          annotation:
            'Shine Connectは、GoogleAPIから受け取った情報の使用および他のアプリケーションへの転送に関して、<a href="https://developers.google.com/terms/api-services-user-data-policy?hl=ja#additional_requirements_for_specific_api_scopes" target="google-policy" class="link">『GoogleAPIサービスのユーザーデータに関するポリシー』</a>に従います。これには、制限付きスコープの要件も含まれます。',
          detail:
            'Googleカレンダー連携すると、現在のスケジュールがサークル上に表示されるようになり、お互いに現在のスケジュールが参照しやすくなります。',
          icon: 'google-calendar.png',
          linkButtonText: 'Googleカレンダーと連携する',
          linked: false,
          name: 'Googleカレンダー',
          unlinkButtonText: 'Googleカレンダーと連携解除する',
        },
      },
      intervalID: undefined,
      memberPopupReady: false,
      menuOpened: false,
      notificationEnabled: false,
      online: false,
      popupMember: {
        // 定義の順番で記述
        /* eslint-disable vue/sort-keys */
        userId: '',
        userName: '',
        image: '',
        departments: [],
        projects: [],
        voiceXs: [],
        /* eslint-enable vue/sort-keys */
      },
      settingTab: 0,
      showedDialog: {
        enableNotification: false,
        externalLink: false,
        logout: false,
        memberCircleWindow: false,
        requestPermission: false,
        requestPermissionBlocked: false,
        requestPermissionGranted: false,
        settings: false,
      },
      voiceXState: {
        colorRotate: 0,
        linkedIds: [],
      },
      websocketMessage: false,
      ws: undefined,
    };
  },

  computed: {
    ...UIChatMapper.mapState(['phraseList', 'quickChatReplyChatId', 'quickChatMessages']),
    ...UICommonMapper.mapState([
      'message',
      'popupUserId',
      'userList',
      'showedAttendanceBookPopup',
      'showedAttendanceEditPopup',
      'showedGreetingPopup',
      'showedMemberCircle',
      'showedMemberPopup',
      'showedProfilePopup',
      'showedQuickChatPopup',
      'showedQuickChatListPopup',
      'showedQuickChatReplyPopup',
      'showedUserMenu',
      'showedPopupBackgroundLayer',
      'showedWebPushRecommendPopup',
      'showedWorkspacePopup',
    ]),
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...DomainAuthMapper.mapGetters(['isAuthenticated']),
    ...UIMemberFilterMapper.mapState(['filter', 'filtered']),
    ...UIRelationshipMapper.mapState(['relationship']),
    ...UINotificationMapper.mapGetters(['webPushToken']),

    combinedItems(): Record<string, ExternalLinkItem> {
      const self = this;
      const items = self._.cloneDeep(self.externalLinkItems);
      const emptyItemsCount = this.desiredExternalLinkItemCount - self._.size(items);
      const emptyItem = {
        annotation: '',
        detail: '',
        icon: '',
        linkButtonText: '連携する',
        linked: false,
        name: '',
        unlinkButtonText: '連携解除する',
      };

      for (let i = 0; i < emptyItemsCount; i += 1) {
        items[`emptyItem${i}`] = emptyItem;
      }

      return items;
    },

    notificationPermissionText(): string {
      return this.notificationEnabled ? '有効' : '無効';
    },

    routeClass(): string {
      return this._.get(this.$router.app.$route.meta, 'class', '');
    },

    userName(): string {
      const name = this._.get(this.userAttributes, 'userName');

      // TODO 実際には必ず名前は入っているので、このコードはリリース前に消す
      if (this._.isEmpty(name)) {
        return '不明なユーザー';
      }

      return name;
    },

    userNameShorten(): string {
      const name = this._.get(this.userAttributes, 'userName');

      // TODO 実際には必ず名前は入っているので、このコードはリリース前に消す
      if (this._.isEmpty(name)) {
        return '不明';
      }

      return name.substring(0, 2);
    },
  },

  watch: {
    menuOpened: {
      async handler() {
        if (this.menuOpened) {
          this.hideAllPopup();
          this.clearPopupUserId();
          this.showPopupBackgroundLayer();
          this.hideAllDialog();
          this.settingTab = 0;
          await this.refreshUserAttributes();
        } else if (!this.showedUserMenu && !this.showedProfilePopup) {
          this.setShowedUserMenu({ showed: this.menuOpened });
          this.hideAllPopup();
        }
      },
    },

    message: {
      handler() {
        if (!this._.isEmpty(this.message.text)) {
          this.showAlert();
        }
      },
      deep: true,
    },

    // メンバーポップアップ表示のユーザーIDに変化があった
    popupUserId: {
      handler() {
        if (this.showedMemberPopup) {
          this.hideProfilePopup();
          this.openMemberPopup();
        } else {
          this.hideMemberPopup();
        }
      },
    },

    quickChatMessages: {
      handler() {
        this.existsChat = this.quickChatMessages.length > 0;

        const unreadChatList = this._.filter(this.quickChatMessages, { unread: 1 });
        this.existsUnreadChat = unreadChatList?.length > 0;
      },
      deep: true,
    },

    // プロフィールポップアップ表示非表示の切り替えがあった
    showedProfilePopup: {
      handler() {
        if (this.showedProfilePopup) {
          this.hideMemberPopup();
        } else {
          this.clearProfileUserId();
          this.hideProfilePopup();
        }
      },
    },

    showedUserMenu: {
      handler() {
        if (this.showedUserMenu !== this.menuOpened) {
          this.menuOpened = this.showedUserMenu;
        }
      },
    },

    userAttributes: {
      handler() {
        this.externalLinkItems.google.linked = !this._.isEmpty(this.userAttributes.google);
      },
      deep: true,
    },
  },

  created() {
    const self = this;

    self.intervalID = window.setInterval(() => {
      if (!self.isAuthenticated(self.$$dayjs())) {
        if (self.$router.currentRoute.name !== 'Login') {
          self.$router.replace('/login');
        }

        if (self.intervalID !== undefined) {
          clearInterval(self.intervalID);

          self.intervalID = undefined;
        }
      }
    }, 1000);

    self.prepareQuickChat();
    self.createSocketListeners();
    self.checkNotificationEnabled();

    if (
      self._.isEmpty(self.relationship) ||
      self.relationship[0].userId !== self.userAttributes.userId
    ) {
      self.refreshRelationship();
    }

    if (!self._.isEmpty(self.message.text)) {
      self.showAlert();
    }

    self.externalLinkItems.google.linked = !self._.isEmpty(self.userAttributes.google);
    // localStorage監視用
    window.addEventListener('storage', self.watchLocalStorage);
  },

  beforeDestroy() {
    this.closeSocket();
    this.$toast.clear();

    if (this.intervalID !== undefined) {
      clearInterval(this.intervalID);

      this.intervalID = undefined;
    }
  },

  methods: {
    ...UIChatMapper.mapActions([
      'clearQuickchatReplyInfo',
      'setPhraseList',
      'setQuickChatMessages',
    ]),
    ...UICommonMapper.mapActions([
      'clearMessage',
      'clearPopupUserId',
      'clearProfileUserId',
      'hideAllPopup',
      'hideAttendanceBookPopup',
      'hideAttendanceEditPopup',
      'hideUserMenu',
      'hideFilterPopup',
      'hideMemberCircle',
      'hideMemberListPopup',
      'hideMemberPopup',
      'hidePopupBackgroundLayer',
      'hideProfilePopup',
      'hideQuickChatListPopup',
      'hideQuickChatPopup',
      'hideQuickChatReplyPopup',
      'hideWebPushRecommendPopup',
      'hideWorkspacePopup',
      'setMemberCircleUserId',
      'setMessage',
      'setNavigating',
      'setProfileUserId',
      'setShowedUserMenu',
      'setUserList',
      'showAttendanceBookPopup',
      'showMemberCircle',
      'showMemberPopup',
      'showPopupBackgroundLayer',
      'showProfilePopup',
      'showQuickChatListPopup',
      'showQuickChatPopup',
      'showWorkspacePopup',
      'toggleShowedNavigationMenu',
    ]),
    ...DomainAuthMapper.mapActions([
      'clearToken',
      'setUserAttributes',
      'setUserAttributesSpecified',
    ]),
    ...UIRelationshipMapper.mapActions(['setRelationship', 'updateMember']),
    ...UIMemberFilterMapper.mapActions(['setMemberList']),
    ...UINotificationMapper.mapActions(['updateWebPushToken', 'removeWebPushToken']),

    changeNotification() {
      if (this.notificationEnabled) {
        this.subscribeWebPush();
      } else {
        this.unsubscribeWebPush();
      }
    },

    async checkNotificationEnabled() {
      const self = this;
      const { userId, webPushToken, webPushEnabled } = self.userAttributes;
      const storedWebPushToken = self.webPushToken(userId);

      const localStorageWebPushTokens = localStorage.getItem('webPushToken');

      if (webPushEnabled) {
        if (
          Notification.permission === 'granted' &&
          !self._.includes(webPushToken, storedWebPushToken)
        ) {
          // WebPushトークンがなければ取得する
          await self.subscribeWebPush(true);
        } else if (Notification.permission === 'denied') {
          // ブラウザ許可していなければ許可ダイアログを表示する
          self.showedDialog.requestPermissionBlocked = true;
        } else if (Notification.permission === 'default') {
          self.showedDialog.enableNotification = true;
        }
      }

      if (
        Notification.permission === 'granted' &&
        !self._.isEmpty(storedWebPushToken) &&
        self._.includes(webPushToken, storedWebPushToken)
      ) {
        self.notificationEnabled = true;
      } else {
        self.notificationEnabled = false;
      }
    },

    async checkOnline() {
      try {
        const date = new Date();
        const timestamp = date.getTime();
        const url = `${window.location.href}?${timestamp}`;
        await fetch(url);
      } catch {
        this.online = false;
        return;
      }
      this.online = true;
    },

    closeAttendanceEditPopup() {
      this.hideAttendanceEditPopup();
    },

    closeGreetingPopup() {
      this.hideAllPopup();
      this.showPopupBackgroundLayer();
      this.showAttendanceBookPopup();
    },

    closeMemberCircle() {
      this.hideMemberCircle();
    },

    closeMemberPopup() {
      this.hideQuickChatPopup();
      this.hidePopupBackgroundLayer();
      this.hideMemberPopup();
    },

    closeProfilePopup() {
      this.hideAllPopup();
    },

    closeQuickChatListPopup() {
      this.hideQuickChatReplyPopup();
      this.hideQuickChatListPopup();
    },

    closeQuickChatPopup() {
      this.hideQuickChatPopup();
    },

    closeSocket() {
      if (this.ws) {
        this.ws.onclose = null;
        this.ws.close();
      }
    },

    closeWebPushRecommendPopup() {
      this.hideWebPushRecommendPopup();
      this.hidePopupBackgroundLayer();
    },

    closeWorkspacePopup() {
      this.hideWorkspacePopup();
      this.hidePopupBackgroundLayer();
    },

    createSocketListeners() {
      const self = this;
      self.ws = new ReconnectingWebSocket(
        settings.webSocket.url,
        undefined,
        settings.webSocket.options
      );

      self.ws.onopen = (e) => {
        // console.info('open', e);
        // ShineConnectはただいま接続を試行中です。のトーストを表示後に再接続が完了したタイミングで以下のトーストを表示
        if (this.websocketMessage === true) {
          self.setMessage({
            color: 'info',
            text: `ShineConnectの接続が回復しました。`,
          });
          this.websocketMessage = false;
        }
      };

      self.ws.onmessage = (e) => {
        // console.info('message:', e.data);
        const message = JSON.parse(e.data);
        let member: PartialMember = {};

        switch (message.kind) {
          case 'status':
            // console.log('check websocket message status', message);
            member = {
              userId: message.userId,
            };
            if (!self._.isUndefined(message.emotion)) {
              member.emotion = message.emotion;
            }
            if (!self._.isUndefined(message.attendanceFlag)) {
              member.attendanceFlag = message.attendanceFlag;
            }
            // console.log('check websocket status member', member);
            self.updateMember({ member });

            if (message.userId === self.userAttributes.userId) {
              self.setUserAttributesSpecified(member);
            }
            break;
          case 'agent': {
            // console.log('check websocket message agent', message);
            const eventList: MemberEvent[] = message.eventList.map((event: MemberEvent) => {
              const format = 'YYYY-MM-DD HH:mm:ss';
              const formattedStart = self.$$dayjs(event.start as number).format(format);
              const formattedEnd = self.$$dayjs(event.end as number).format(format);
              return {
                ...event,
                end: formattedEnd,
                start: formattedStart,
              };
            });
            member = {
              currentApp: message.currentApp,
              eventList,
              userId: message.userId,
            };
            this.updateMember({ member });

            if (message.userId === self.userAttributes.userId) {
              self.setUserAttributesSpecified(member);
            }
            break;
          }
          case 'chat':
            // console.log('check websocket message chat', message);
            this.showChatMessage(message);
            break;
          case 'voicex':
            // console.log('check websocket message voicex', message);
            self.highlightVoiceXPairing(message);
            break;
          default:
        }
      };
      self.ws.onclose = (e) => {
        // ネット接続あるかないかをチェック
        this.checkOnline();

        self.ws = undefined;
        self.$$log.error(e);
        // コードが1000ではなく、ネットの接続がある場合はトーストを表示
        if (e.code !== 1000 && self.online === true) {
          // ShineConnectはただいま接続を試行中です。トーストを連続で表示しないよう設定
          if (this.websocketMessage === false) {
            self.setMessage({
              color: 'warning',
              text: `ShineConnectはただいま接続を試行中です。`,
            });
            this.websocketMessage = true;
          }
        }
      };

      self.ws.onerror = (e) => {
        self.$$log.error(e);
      };

      window.onunload = () => {
        self.closeSocket();
      };
    },

    async deleteGoogleToken() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;
      try {
        const response = await GoogleService.deleteGoogleToken(workspaceId, userId);
        this.showedDialog.externalLink = false;
        await self.refreshUserAttributes();
        if (response.status === 204) {
          self.setMessage({ color: 'success', text: 'Googleカレンダー連携解除に成功しました。' });
        } else {
          self.setMessage({ color: 'error', text: 'Googleカレンダー連携解除に失敗しました。' });
          self.$$log.error(response.data.message);
        }
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },

    async goGoogleOAuth() {
      const self = this;

      try {
        const response = await GoogleService.getAuthUrl();
        const authUrl = this._.get(response, 'authUrl');

        window.location.href = authUrl;
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },

    gotoOtherPage(url: string) {
      if (this.$route.path !== url) {
        this.$router.push(url);
      }
    },

    hideAllDialog() {
      this._.forEach(this.showedDialog, (value, key) => {
        this.showedDialog[key] = false;
      });
    },

    // WebSocketメッセージは不定なのでanyを許容
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    highlightVoiceXPairing(message: any) {
      const self = this;

      // 通話開始時
      if (message.currentApp.voiceX) {
        if (message.currentApp.outbound === false) {
          // 発話・受話双方のmessageが届くので、linkedIdを管理し、1度だけカラーを変更する
          if (!self.voiceXState.linkedIds.includes(message.currentApp.linkedId)) {
            self.voiceXState.linkedIds.push(message.currentApp.linkedId);
            self.voiceXState.colorRotate += 1;
          }
        }

        // 4色の配列定義済みペアリングカラーのインデックスを与える
        const pairingColor = message.currentApp.outbound
          ? undefined
          : self.voiceXState.colorRotate % 4;

        const member: PartialMember = {
          currentApp: message.currentApp,
          userId: message.userId,
          voiceXPairingColor: pairingColor,
        };
        self.updateMember({ member });
      }
      // 通話終了時
      else {
        const member: PartialMember = {
          currentApp: message.currentApp,
          userId: message.userId,
          voiceXPairingColor: undefined,
        };
        self.updateMember({ member });

        self._.remove(self.voiceXState.linkedIds, (id) => id === message.currentApp.linkedId);
      }
    },

    async logout() {
      const self = this;

      self.setNavigating({ navigating: true });
      self.closeSocket();

      try {
        // self.clearToken();
        // localStorageの監視イベント解除
        window.removeEventListener('storage', self.watchLocalStorage);
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }

      // APIがエラーを返した場合でもログアウトを行う
      this.$router.push('/logout');
    },

    onclickBackgroundLayer() {
      // ログイングリーティング中は閉じない
      if (this.showedGreetingPopup) {
        return;
      }

      this.menuOpened = false;
      this.hideAllPopup();
    },

    openExternalLinkDialog(key: string) {
      this.hideAllDialog();
      this.currentExternalLinkItem = this.externalLinkItems[key];
      this.showedDialog.externalLink = true;
    },

    // イベントなのでanyを許容
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openMemberCircle(event?: any) {
      this.hideAllPopup();
      this.hidePopupBackgroundLayer();
      if (!this._.isUndefined(event)) {
        this.setMemberCircleUserId({ userId: event.userId });
        this.showMemberCircle();
      }
    },

    async openMemberPopup() {
      this.showPopupBackgroundLayer();
      this.hideProfilePopup();
      this.memberPopupReady = false;

      const userId = this.popupUserId;
      const { workspaceId } = this.userAttributes;

      try {
        const responseUser = await UserService.getUser(workspaceId, userId);
        this.popupMember = {
          // 定義の順番で記述
          /* eslint-disable vue/sort-keys */
          userId,
          userName: this._.get(responseUser, 'userName'),
          image: this._.get(responseUser, 'image', ''),
          departments: this._.get(responseUser, 'departments', []),
          projects: this._.get(responseUser, 'projects', []),
          voiceXs: this._.get(responseUser, 'voiceXs', []),
          /* eslint-enable vue/sort-keys */
        };

        this.memberPopupReady = true;
        this.showMemberPopup();
      } catch (error: any) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    openMyProfile() {
      this.openProfilePopup();
    },

    openNews() {
      this.hidePopupBackgroundLayer();
      window.open(settings.externalLinkURLs.news, '_blank');
    },

    openPrivacyPolicy() {
      this.hidePopupBackgroundLayer();
      window.open(settings.externalLinkURLs.privacyPolicy, '_blank');
    },

    // イベントなのでanyを許容
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openProfilePopup(event?: any) {
      const self = this;
      const profileUserId = self._.isUndefined(event) ? self.userAttributes.userId : event.userId;
      self.hideAllPopup();
      self.setProfileUserId({ userId: profileUserId });
      self.showProfilePopup();
    },

    openQuickChatPopup() {
      this.showQuickChatPopup();
    },

    openSettingsDialog() {
      this.hideAllDialog();

      switch (Notification.permission) {
        case 'granted':
          this.checkNotificationEnabled();
          this.showedDialog.requestPermissionGranted = true;
          break;
        case 'denied':
          this.showedDialog.requestPermissionBlocked = true;
          break;
        default:
          this.showedDialog.enableNotification = true;
      }

      this.showedDialog.settings = true;
    },

    openWorkspacePopup() {
      this.showPopupBackgroundLayer();
      this.showWorkspacePopup();
    },

    async prepareQuickChat() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;
      const procedures = [
        SearchService.memberSearch(InitialSearchConditions),
        PhraseService.getPhraseList(workspaceId),
        ChatService.getChat(workspaceId, userId),
      ];

      try {
        const [responseSearch, responseMessageList, responseChat] = await Promise.all(procedures);

        // ユーザー一覧をストアにセット
        const sortedUserList = self._.sortBy(responseSearch, 'kana');
        self.setUserList({ users: sortedUserList });

        // クイックチャットの文書情報をストアにセット
        self.setPhraseList({ list: responseMessageList });

        // クイックチャット受信一覧をストアにセット
        self.setQuickChatMessages({ messages: responseChat });
      } catch (error: any) {
        this.$emit('send-quick-chat', false);
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    async refreshRelationship() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      const condition = self.filtered ? self.filter : InitialSearchConditions;

      try {
        const procedures = [
          RelationshipService.getRelationshipSorted(workspaceId, userId),
          SearchService.memberSearch(condition),
          SearchService.memberSearch(InitialSearchConditions),
        ];

        const [resRelationship, resSearch, resSearchAll] = await Promise.all(procedures);
        self.setMemberList({ memberList: resSearch });

        // 自分を中心（配列0番）にする
        const relationship: Relationship = [];
        const me = self._.find(resSearchAll, { userId });
        // emotionとattendanceFlgは後から追加するもの。watchイベントで検知できないので、ここで追加してしまう。
        if (!me.emotion) {
          me.emotion = '';
        }
        if (!me.attendanceFlag) {
          me.attendanceFlag = 0;
        }
        relationship.push(me);
        if (!self._.isEmpty(resSearch)) {
          // サーチの結果をリレーションシップ順に並べる
          const sortedMembers = resRelationship
            // APIレスポンスなのでanyを許容
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((x: any) => resSearch.find((v: any) => v.userId === x.userId))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((e: any) => e);

          // 自分以外を関係が近い順に並べる
          self._.forEach(sortedMembers, (member) => {
            const pushmember = self._.cloneDeep(member);
            // emotionとattendanceFlgは後から追加するもの。watchイベントで検知できないので、ここで追加してしまう。
            if (pushmember.userId !== userId) {
              if (!pushmember.emotion) {
                pushmember.emotion = '';
              }
              if (!member.attendanceFlag) {
                pushmember.attendanceFlag = 0;
              }
              relationship.push(pushmember);
            }
          });
        }
        self.setRelationship({ relationship: self._.cloneDeep(relationship) });
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },

    async refreshUserAttributes() {
      const self = this;

      try {
        const { workspaceId, userId } = self.userAttributes;
        const responseUser = await UserService.getUser(workspaceId, userId);

        const attr: UserAttributes = {
          // 型定義の順序で記述
          /* eslint-disable vue/sort-keys */
          workspaceId,
          userId,
          adminLevel: self._.get(responseUser, 'adminLevel'),
          email: self._.get(responseUser, 'email'),
          google: self._.get(responseUser, 'google.resourceId'),
          userName: self._.get(responseUser, 'userName'),
          kana: self._.get(responseUser, 'kana'),
          image: self._.get(responseUser, 'image'),
          departments: self._.get(responseUser, 'departments', []),
          position: self._.get(responseUser, 'position'),
          projects: self._.get(responseUser, 'projects', []),
          phoneNo: self._.get(responseUser, 'phoneNo'),
          voiceXs: self._.get(responseUser, 'voiceXs', []),
          timezone: self._.get(responseUser, 'timezone', settings.defaultTimezone),
          status: self._.get(responseUser, 'status'),
          emotion: self._.get(responseUser, 'emotion'),
          place: self._.get(responseUser, 'place'),
          device: self._.get(responseUser, 'device'),
          currentApp: self._.get(responseUser, 'currentApp'),
          chatUnread: self._.get(responseUser, 'chatUnread'),
          regDate: self._.get(responseUser, 'regDate'),
          updDate: self._.get(responseUser, 'updDate'),
          webPushToken: self._.get(responseUser, 'webPushToken'),
          webPushEnabled: self._.get(responseUser, 'webPushEnabled'),
          /* eslint-enable vue/sort-keys */
        };
        self.setUserAttributes(attr);

        const member: PartialMember = {
          currentApp: self._.get(responseUser, 'currentApp'),
          eventList: self._.get(responseUser, 'eventList'),
          userId,
        };
        self.updateMember({ member });
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
        self.setNavigating({ navigating: false });
      }
    },

    async requestNotificationPermission() {
      const self = this;

      self.hideWebPushRecommendPopup();

      if (!('Notification' in window)) {
        console.error('This browser does not support desktop notification');
        return;
      }

      if (Notification.permission === 'default') {
        self.hideAllDialog();
        self.showedDialog.requestPermission = true;

        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
          await self.subscribeWebPush();
        } else {
          self.showedDialog.requestPermission = false;
          self.showedDialog.settings = true;
          self.showedDialog.requestPermissionBlocked = true;
        }
      }
    },

    showAlert() {
      const self = this;
      const id = new Date().getTime().toString();
      const content = {
        component: Alert,
        listeners: {
          close: (alertId: string) => {
            self.$toast.dismiss(alertId);
          },
        },
        props: {
          id: `alert-${id}`,
          message: self.message,
        },
      };
      const options = self._.assignIn({}, settings.toast.messageOptions, { id: `alert-${id}` });

      switch (self.message.color) {
        case 'success':
          self.$toast.success(content, options);
          break;
        case 'info':
          self.$toast.info(content, options);
          break;
        case 'warning':
          self.$toast.warning(content, options);
          break;
        case 'error':
          self.$toast.error(content, options);
          break;
        default:
          self.$toast(content, options);
      }

      self.clearMessage();
    },

    async showChatMessage(chat: ReceivedChat) {
      const self = this;
      const { chatId, workspaceId, fromId, message, regDate, toId } = chat;

      try {
        const userFrom = self._.find(self.userList, { userId: fromId });

        const content = {
          component: QuickChatNotification,
          props: {
            chatId,
            message,
            regDate,
            userId: fromId,
            userName: userFrom?.userName,
          },
        };
        const options = self._.assignIn({}, settings.toast.messageOptions, {
          id: chatId,
          onClose: () => {
            self.$emit('close', chatId);
          },
        });

        self.$toast(content, options);

        // クイックチャットアイコン更新のため、クイックチャット受信一覧更新情報をストアにセット
        const responseChat = await ChatService.getChat(workspaceId, toId);
        self.setQuickChatMessages({ messages: responseChat });
      } catch (error: any) {
        this.$$log.error(error);
        this.setMessage({ color: 'error', text: error.message });
      }
    },

    showLogoutDialog() {
      this.hidePopupBackgroundLayer();
      this.showedDialog.logout = true;
    },

    async showQuickChatReplyResult(success: boolean, memberId: string) {
      if (success) {
        const { workspaceId } = this.userAttributes;

        try {
          await WebPushService.notify({
            body: MESSAGE_QUICK_CHAT_RECEIVED,
            title: TITLE_WEB_PUSH_RECEIVED,
            url: settings.mainDomainURL,
            userId: memberId,
            workspaceId,
          });
        } catch (error: any) {
          this.$$log.error(error);
          this.setMessage({ color: 'error', text: error.message });
        }
      }

      this.$toast.dismiss(this.quickChatReplyChatId);

      this.hideAllPopup();
      this.clearQuickchatReplyInfo();
      this.refreshRelationship();

      const content = {
        component: QuickChatSendResult,
        props: {
          result: success,
        },
      };
      const options = this._.assignIn({}, settings.toast.messageOptions, { timeout: 2000 });
      this.$toast(content, options);
    },

    async showQuickChatResult(success: boolean, memberId: string) {
      if (success) {
        const { workspaceId } = this.userAttributes;

        try {
          await WebPushService.notify({
            body: MESSAGE_QUICK_CHAT_RECEIVED,
            title: TITLE_WEB_PUSH_RECEIVED,
            url: settings.mainDomainURL,
            userId: memberId,
            workspaceId,
          });
        } catch (error: any) {
          this.$$log.error(error);
          this.setMessage({ color: 'error', text: error.message });
        }
      }

      this.hideAllPopup();
      this.refreshRelationship();

      const content = {
        component: QuickChatSendResult,
        props: {
          result: success,
        },
      };
      const options = this._.assignIn({}, settings.toast.messageOptions, { timeout: 2000 });
      this.$toast(content, options);
    },

    async subscribeWebPush(silent = false) {
      const self = this;

      try {
        self.setNavigating({ navigating: true });

        // ServiceWorker登録 → WebPushToken取得 → APIにsubscribe → localStorageにストア
        const response = await WebPushService.getWebPushConfig();
        const firebaseConfig = { ...response.config };
        const vapidKey = response.key;
        const firebaseApp = initializeApp(firebaseConfig);
        const messaging = getMessaging(firebaseApp);
        const registration = await navigator.serviceWorker.register('./firebase-messaging-sw.js');
        const token = await getToken(messaging, {
          serviceWorkerRegistration: registration,
          vapidKey,
        });

        if (token) {
          const { workspaceId, userId } = self.userAttributes;
          const subscribeParams = { token, userId, workspaceId };
          // APIにsubscribeして、登録済みのトークン配列を取得する
          const responseSubscribe = await WebPushService.subscribe(subscribeParams);

          if (self._.includes(responseSubscribe.webPushToken, token)) {
            self.updateWebPushToken({ token, userId });
          }

          await self.refreshUserAttributes();
        }

        self.notificationEnabled = true;
        self.showedDialog.requestPermission = false;

        if (!silent) {
          self.showedDialog.requestPermissionGranted = true;
        }
        self.setMessage({ color: 'success', text: 'デスクトップ通知が有効になりました。' });
        self.setNavigating({ navigating: false });
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
        self.setNavigating({ navigating: false });
      }
    },

    toggleAttendanceBookPopup() {
      if (this.showedAttendanceBookPopup) {
        this.hideAllPopup();
      } else {
        this.menuOpened = false;
        this.hideAllPopup();
        this.$nextTick().then(() => {
          this.showAttendanceBookPopup();
          this.showPopupBackgroundLayer();
        });
      }
    },

    toggleQuickChatListPopup() {
      if (this.showedQuickChatListPopup) {
        this.hideQuickChatListPopup();
        this.hideQuickChatReplyPopup();
        this.hidePopupBackgroundLayer();
      } else {
        this.menuOpened = false;
        this.hideAllPopup();
        this.$nextTick().then(() => {
          this.showQuickChatListPopup();
          this.showPopupBackgroundLayer();
        });
      }
    },

    toggleQuickChatPopup() {
      if (this.showedQuickChatPopup) {
        this.hideQuickChatPopup();
      } else {
        this.openQuickChatPopup();
      }
    },

    async unlinkGoogleCalendar() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;
      try {
        const response = await GoogleService.deleteCalendarLink(workspaceId, userId);
        this.showedDialog.externalLink = false;
        await self.refreshUserAttributes();
        if (response.result === 'OK') {
          self.setMessage({ color: 'success', text: 'Googleカレンダー連携解除に成功しました。' });
        } else {
          // self.setMessage({ color: 'error', text: 'Googleカレンダー連携解除に失敗しました。' });
          self.$$log.error(response.message);
          await self.deleteGoogleToken();
        }
      } catch (error: any) {
        self.$$log.error(error);
        // self.setMessage({ color: 'error', text: error.message });
        await self.deleteGoogleToken();
      }
    },

    async unsubscribeWebPush() {
      const self = this;

      try {
        self.setNavigating({ navigating: true });

        // APIにunsubscribe → localStorageから削除
        const { workspaceId, userId } = self.userAttributes;
        const token = self.webPushToken(userId);
        const subscribeParams = { token, userId, workspaceId };
        const responseUnsubscribe = await WebPushService.unsubscribe(subscribeParams);

        if (!self._.includes(responseUnsubscribe.webPushToken, token)) {
          self.removeWebPushToken({ userId });
        }

        await self.refreshUserAttributes();

        self.setNavigating({ navigating: false });
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
        self.setNavigating({ navigating: false });
      }
    },

    watchLocalStorage(event: any) {
      const self = this;
      let newVal;
      let oldVal;
      switch (event.key) {
        case 'loginDate':
          newVal = JSON.parse(event.newValue);
          oldVal = JSON.parse(event.oldValue);
          if (oldVal.date !== newVal.date) {
            try {
              self.clearToken();
              // localStorageの監視イベント解除
              window.removeEventListener('storage', self.watchLocalStorage);
            } catch (error: any) {
              self.$$log.error(error);
              self.setMessage({ color: 'error', text: error.message });
            }
            self.$router.replace('/login?another_tab_auth=true');
          }
          break;
        default:
          break;
      }
    },
  },
});
