

















































import _ from 'lodash';
import Vue from 'vue';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';
import type { RouteConfig } from 'vue-router';

type MenuItem = {
  icon: string;
  path: string;
  title: string;
  position: string;
  admin: boolean;
};

// ルートのパスとメタフィールドを基にナビゲーションドロワーのメニュー項目を生成する
function generateMenuItems(
  routes: RouteConfig[] | undefined,
  menuItems: MenuItem[],
  parentPath = ''
) {
  _.forEach(routes, (route: RouteConfig) => {
    const path = _.get(route, 'path');
    let pathPrefix = '';

    if (parentPath.length > 0 && !_.endsWith(parentPath, '/')) {
      pathPrefix = `${parentPath}/`;
    } else {
      pathPrefix = parentPath;
    }

    if (_.get(route, 'meta.isMenuItem')) {
      const admin = _.get(route, 'meta.admin');
      const icon = _.get(route, 'meta.icon');
      const title = _.get(route, 'meta.title');
      const position = _.get(route, 'meta.position');

      menuItems.push({ admin, icon, path: pathPrefix + path, position, title });
    }

    const children = _.get(route, 'children');

    if (_.isArray(children)) {
      generateMenuItems(children, menuItems, pathPrefix + path);
    }
  });
}

export default Vue.extend({
  name: 'TheNavigationMenu',

  data(): {
    menuItems: MenuItem[];
  } {
    return {
      menuItems: [],
    };
  },

  computed: {
    ...UICommonMapper.mapState(['showedNavigationMenu']),
    ...UIWorkspaceMapper.mapState(['workspace']),
    ...DomainAuthMapper.mapState(['userAttributes']),

    computedShowed: {
      get(): boolean {
        return this.showedNavigationMenu;
      },
      set(value: boolean) {
        this.setShowedNavigationMenu({ showed: value });
      },
    },

    menuItemsBottom(): MenuItem[] {
      return this._.filter(this.menuItems, { position: 'bottom' });
    },

    menuItemsMiddle(): MenuItem[] {
      const userLevel = this._.get(this.userAttributes, 'adminLevel', '');
      return userLevel > 2
        ? this._.filter(this.menuItems, { admin: false, position: 'middle' })
        : this._.filter(this.menuItems, { position: 'middle' });
    },

    menuItemsTop(): MenuItem[] {
      return this._.filter(this.menuItems, { position: 'top' });
    },

    workspaceImageUrl(): string {
      return this._.get(this.workspace, 'image');
    },
  },

  created() {
    const { routes } = this.$router.options;

    generateMenuItems(routes, this.menuItems);
  },

  methods: {
    ...UICommonMapper.mapActions(['setShowedNavigationMenu']),

    clickWorkspaceIcon() {
      this.$emit('click-workspace-icon');
    },
  },
});
