















































import Vue from 'vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UIChatMapper } from '@/store/modules/ui/chat';
import { UICommonMapper } from '@/store/modules/ui/common';
import type { Chat } from '@/store/modules/ui/chat';
import type { User } from '@/store/modules/ui/common';

const ChatService = ServiceFactory.get('chat');

export default Vue.extend({
  name: 'QuickChatListPopup',

  data(): {
    selectedChatId: string;
  } {
    return {
      selectedChatId: '',
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UIChatMapper.mapState(['phraseList', 'quickChatMessages']),
    ...UICommonMapper.mapState(['message', 'userList', 'showedQuickChatListPopup']),
  },

  watch: {
    showedQuickChatListPopup: {
      handler() {
        if (this.showedQuickChatListPopup) {
          this.getChatList();
          this.clearQuickchatReplyInfo();
          this.selectedChatId = '';
        }
      },
    },
  },

  methods: {
    ...UIChatMapper.mapActions([
      'clearQuickchatReplyInfo',
      'setQuickChatMessages',
      'setQuickchatReplyInfo',
    ]),
    ...UICommonMapper.mapActions([
      'hidePopupBackgroundLayer',
      'setMessage',
      'showQuickChatReplyPopup',
      'hideQuickChatReplyPopup',
    ]),

    closePopup() {
      this.$emit('click-close');
      this.hidePopupBackgroundLayer();
    },

    async getChatList() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      try {
        const responseChat = await ChatService.getChat(workspaceId, userId);
        self.setQuickChatMessages({ messages: responseChat });
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },

    // 選択メッセージのactive-classを有効にする
    itemSelected(chatId: string): boolean {
      return chatId === this.selectedChatId;
    },

    async readChat(chat: Chat) {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      if (self.selectedChatId !== chat.chatId) {
        self.selectedChatId = chat.chatId;

        // 未読なら既読にする
        if (chat.unread) {
          try {
            await ChatService.readChat(workspaceId, userId, { chatId: chat.chatId });
            await self.getChatList();
          } catch (error: any) {
            self.$$log.error(error);
            self.setMessage({ color: 'error', text: error.message });
          }
        }

        self.setQuickchatReplyInfo({ chatId: chat.chatId, userId: chat.fromId });
        self.showQuickChatReplyPopup();
      } else {
        self.selectedChatId = '';
        this.hideQuickChatReplyPopup();
      }
    },

    sentTime(dt: string): string {
      return this.$$dayjs(dt).format(this.$$dayjsFormats.quickchatListFormatDefault);
    },

    userName(userId: string): string {
      const user: User | undefined = this._.find(this.userList, { userId });
      if (this._.isUndefined(user)) {
        return '不明なユーザー';
      }
      return user.userName;
    },
  },
});
