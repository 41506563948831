








export default {
  name: 'IconUser',
};
