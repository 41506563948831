














import Vue from 'vue';
import type { Message } from '@/store/modules/ui/common';

export default Vue.extend({
  name: 'Alert',

  props: {
    id: {
      type: String,
      required: true,
    },
    message: {
      type: Object as Vue.PropType<Message>,
      required: true,
    },
  },

  computed: {
    color(): string {
      return `alert-${this.message.color}`;
    },
  },

  methods: {
    closeAlert() {
      this.$emit('close', this.id);
    },
  },
});
