






















































import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import BaseButton from '@/components/generic/BaseButton.vue';
import ChatService from '@/services/ui/ChatService';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UIChatMapper } from '@/store/modules/ui/chat';
import { UICommonMapper } from '@/store/modules/ui/common';
import type { Phrase } from '@/store/modules/ui/chat';

const PhraseService = ServiceFactory.get('phrase');

export default Vue.extend({
  name: 'QuickChatPopup',

  components: {
    BaseButton,
  },

  data(): {
    chatText: string;
    preparedQuickChatMessages: Phrase[];
    selectedMessage: string;
    showed: boolean;
  } {
    return {
      chatText: '',
      preparedQuickChatMessages: [],
      selectedMessage: '',
      showed: false,
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['popupUserId', 'showedQuickChatPopup']),
    ...UIChatMapper.mapState(['phraseList']),
  },

  watch: {
    showedQuickChatPopup: {
      handler() {
        if (this.showedQuickChatPopup) {
          this.chatText = '';
          this.$v.$reset();
          this.loadQuickChatPhrases();
        }
      },
    },
  },

  created() {
    this.loadQuickChatPhrases();
  },

  methods: {
    ...UIChatMapper.mapActions(['setPhraseList']),
    ...UICommonMapper.mapActions(['setMessage']),

    closePopup() {
      this.$emit('click-close');
    },

    // 選択メッセージのcss class
    itemSelected(phraseId: string): boolean {
      return phraseId === this.selectedMessage;
    },

    async loadQuickChatPhrases() {
      const self = this;
      // 選択されている項目を解除
      self.selectedMessage = '';
      const { workspaceId } = self.userAttributes;
      const responseMessageList = await PhraseService.getPhraseList(workspaceId);
      self.setPhraseList({ list: responseMessageList });
      self.preparedQuickChatMessages = this._.filter(this.phraseList, { kind: 'chat' });
    },

    selectMessage(phraseId: string) {
      const self = this;
      self.selectedMessage = phraseId;

      self.chatText = self._.get(
        self._.find(self.preparedQuickChatMessages, { phraseId }),
        'text',
        ''
      );
    },

    async sendQuickChat() {
      const self = this;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      const { workspaceId, userId } = self.userAttributes;

      try {
        await ChatService.sendFreeChat({
          memberId: self.popupUserId,
          message: self.chatText,
          userId,
          workspaceId,
        });

        self.$emit('send-quick-chat', true, self.popupUserId);
      } catch (error: any) {
        self.$emit('send-quick-chat', false, self.popupUserId);
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
      self.selectedMessage = '';
    },
  },

  validations() {
    return {
      chatText: {
        // 表示順序で記述
        /* eslint-disable vue/sort-keys */
        required,
        maxLength: maxLength(128),
        /* eslint-enable vue/sort-keys */
      },
    };
  },
});
