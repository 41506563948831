





































import Vue from 'vue';
import { MESSAGE_CHANGE_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'EditStatusPopup',

  data(): {
    selected: number;
  } {
    return {
      selected: 0,
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
  },

  created() {
    this.selected = this.userAttributes.emotion;
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage']),
    ...DomainAuthMapper.mapActions(['setUserAttributesSpecified']),

    closePopup() {
      this.selected = this.userAttributes.emotion;
      this.$emit('close-popup');
    },

    select(emo: number) {
      if (this.selected === emo) {
        this.selected = 0;
      } else {
        this.selected = emo;
      }
    },

    async updateStatus() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;
      const params = {
        emotion: this.selected,
      };

      try {
        const response = await AuthService.updateUserAttributes(workspaceId, userId, params);

        self.setMessage({
          color: 'success',
          text: MESSAGE_CHANGE_DONE,
        });

        const emotion = self._.get(response, 'emotion', 0);
        self.setUserAttributesSpecified({ emotion });

        await AuthService.tokenRefresh({ userId, workspaceId });

        self.closePopup();
      } catch (error) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },
  },
});
