

















































































































































import dayjs from 'dayjs';
import Vue from 'vue';
import { ATTENDANCE_KIND } from '@/resources/defines';
import getCustomFormatAttendanceTime from '@/resources/functions/getCustomFormatAttendanceTime';
import getEndOfDayTimeDisplay from '@/resources/functions/getEndOfDayTimeDisplay';
import getPrefixAttendanceTime from '@/resources/functions/getPrefixAttendanceTime';
import ServiceFactory from '@/services/ui/ServiceFactory';
import settings from '@/settings';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UIAttendanceMapper, InitialRecordItem } from '@/store/modules/ui/attendance';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIRelationshipMapper } from '@/store/modules/ui/relationship';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';
import type {
  TimeDataItem,
  RecordItem,
  EditingDataItem,
  EditingData,
} from '@/store/modules/ui/attendance';
import type { PartialMember } from '@/store/modules/ui/relationship';
import type { DataTableHeader } from 'vuetify';

const AttendanceService = ServiceFactory.get('attendance');

// POST /attendance/list/all で取得できる打刻の形式
interface AttendanceItem {
  ctime: string; // 修正打刻日時 "YYYY-MM-DD HH:mm:ss"
  date: string; // "YYYYMMDD"
  kind: number; // 1:出勤、2:退勤
  reason: string; // 修正理由 "打刻忘れ"
  regDate: string; // 登録日時 "2023-04-02T16:18:33.455Z"
  status: number; // 0:有効、1:削除
  time: string; // 打刻日時 "YYYY-MM-DD HH:mm:ss"
  updDate: string; // 更新日時 "2023-04-07T01:36:56.063Z"
}

export default Vue.extend({
  name: 'AttendanceBookPopup',

  props: {
    forceRefresh: {
      type: Boolean,
    },
  },

  data(): {
    attention: string;
    calendarYearMonth: dayjs.Dayjs;
    currentDate: string;
    currentTime: string;
    displayDate: string;
    displayTime: string;
    footerProps: Record<string, unknown>;
    headers: DataTableHeader[];
    intervalId: number | undefined;
    lastPunchIn: string;
    lastPunchInAs: string;
    lastPunchOut: string;
    lastPunchOutAs: string;
    nextDate: string;
    punchAsTomorrow: boolean;
    punchingDateTime: string;
    records: RecordItem[];
    recordsPreviousMonth: RecordItem[];
    referenceDateTime: dayjs.Dayjs;
  } {
    return {
      attention: '',
      calendarYearMonth: this.$$dayjs(),
      currentDate: '',
      currentTime: '',
      displayDate: '',
      displayTime: '',
      footerProps: {
        'items-per-page-options': [-1],
      },
      headers: [
        { class: ['text-header'], text: '日付', value: 'date' },
        { class: ['text-header'], text: '出勤', value: 'punchIn' },
        { class: ['text-header'], text: '退勤', value: 'punchOut' },
      ],
      intervalId: undefined,
      lastPunchIn: '',
      lastPunchInAs: '',
      lastPunchOut: '',
      lastPunchOutAs: '',
      nextDate: '',
      punchAsTomorrow: false,
      punchingDateTime: '',
      records: [],
      recordsPreviousMonth: [],
      referenceDateTime: this.$$dayjs(),
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['showedAttendanceBookPopup']),
    ...UIAttendanceMapper.mapState(['updateComplete']),
    ...UIRelationshipMapper.mapState(['relationship']),
    ...UIWorkspaceMapper.mapState(['workspace']),

    // 日替わり設定時刻（一日の開始時刻）
    atdBorderTime(): string {
      return this._.get(this.workspace, 'atdBorderTime', settings.attendance.atdBorderTime);
    },

    displayedAttention(): boolean {
      return !this._.isEmpty(this.attention);
    },

    displayedPunchingDateTime(): boolean {
      return !this._.isEmpty(this.punchingDateTime);
    },

    // 表示上の一日の終了時刻
    endOfDayTimeDisplay(): string {
      return getEndOfDayTimeDisplay(this.atdBorderTime);
    },

    // カレンダーが現在月か
    isCurrentMonthCalendar(): boolean {
      const currentYearMonth = this.$$dayjs(this.displayDate, 'YYYY/M/D ddd').format('YYYYMM');
      return this.calendarYearMonth.format('YYYYMM') === currentYearMonth;
    },

    // 出勤打刻の後、退勤打刻がまだか
    isPunchOutPending(): boolean {
      // まったく打刻なし
      if (this._.isEmpty(this.lastPunchIn) && this._.isEmpty(this.lastPunchOut)) {
        return false;
      }
      // 最終退勤打刻のみ
      if (this._.isEmpty(this.lastPunchIn) && !this._.isEmpty(this.lastPunchOut)) {
        return false;
      }
      // 最終出勤打刻のみ
      if (!this._.isEmpty(this.lastPunchIn) && this._.isEmpty(this.lastPunchOut)) {
        return true;
      }
      // 最終出勤打刻、最終退勤打刻ともに あり → 日時比較
      return this.$$dayjs(this.lastPunchIn).isAfter(this.lastPunchOut);
    },

    // 勤務上限時間を超えているかどうか
    isWorkTimeLimitExceeded(): boolean {
      const now = this.$$dayjs();
      const lastPunchInTime = this.$$dayjs(this.lastPunchIn);
      const elapsedTime = now.diff(lastPunchInTime, 'hour', true);
      const atdLimitTime = this._.get(
        this.workspace,
        'atdLimitTime',
        settings.attendance.atdLimitTime
      );
      const workTimeLimit = parseInt(atdLimitTime, 10);

      return elapsedTime > workTimeLimit;
    },

    // 出勤中かどうか
    isWorking(): boolean {
      const { userId } = this.userAttributes;
      if (this._.isEmpty(userId) || this._.isEmpty(this.relationship)) {
        return false;
      }
      const member = this._.find(this.relationship, { userId }) as PartialMember;
      if(member && member.attendanceFlag) {
        return member.attendanceFlag === 1;
      }
      return false;
    },

    // 前日からの勤務中かどうか
    isWorkingFromPrevious(): boolean {
      if (!this.isWorking) {
        return false;
      }

      // 前日の出勤打刻か
      const referenceDateTime = this.getReferenceDateTime();
      return this.$$dayjs(this.lastPunchIn).isBefore(referenceDateTime);
    },
  },

  watch: {
    forceRefresh: {
      handler() {
        if (this.forceRefresh) {
          this.getAttendanceRecords();
        }
      },
    },

    records: {
      handler() {
        this.$nextTick().then(() => {
          this.initializeScrollbars();
        });
      },
    },

    showedAttendanceBookPopup: {
      async handler() {
        if (this.showedAttendanceBookPopup) {
          this.updateCurrentDateTime();
          this.calendarYearMonth = this.$$dayjs(this.displayDate, 'YYYY/M/D ddd').startOf('month');
          this.punchingDateTime = '';
          this.punchAsTomorrow = false;
          this.clearEditingItem();
          await this.getAttendanceRecords();
          this.setInitialAttention();
        } else {
          this.hideAttendanceEditPopup();
        }
      },
    },

    updateComplete: {
      handler() {
        if (this.updateComplete) {
          this.getAttendanceRecords();
          this.resetUpdateComplete();
        }
      },
    },
  },

  created() {
    this.updateCurrentDateTime();
    this.calendarYearMonth = this.$$dayjs(this.displayDate).startOf('month');
    this.getPreviousAttendanceRecords();
    this.getAttendanceRecords();
    this.intervalId = window.setInterval(this.updateCurrentDateTime, 1000);
  },

  beforeDestroy() {
    // コンポーネントが破棄される前に、setIntervalを解除する
    clearInterval(this.intervalId);
  },

  methods: {
    ...UIAttendanceMapper.mapActions(['clearEditingItem', 'setEditingItem', 'resetUpdateComplete']),
    ...UICommonMapper.mapActions([
      'setMessage',
      'hideAttendanceEditPopup',
      'showAttendanceEditPopup',
    ]),

    clearAttention() {
      this.attention = '';
    },

    closePopup() {
      this.$emit('close-popup');
    },

    dateFormatted(date: string): string {
      return this.$$dayjs(date).format('D(ddd)');
    },

    // 打刻履歴を取得する
    async getAttendanceRecords() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      if (self._.isUndefined(self.calendarYearMonth)) {
        self.calendarYearMonth = self.$$dayjs(self.displayDate).startOf('month');
      }

      const recordsToAdd: RecordItem[] = [];
      const firstDay = self.calendarYearMonth.startOf('month');
      const lastDay = self.calendarYearMonth.endOf('month');
      const daysInMonth = lastDay.diff(firstDay, 'days') + 1;
      let currentDate = firstDay;

      // DataTable用に日付だけの配列を用意する
      while (currentDate.isSameOrBefore(lastDay)) {
        const ymd = currentDate.format('YYYY-MM-DD');
        const emptyRecordItem = self._.defaults({ date: ymd }, self._.cloneDeep(InitialRecordItem));

        recordsToAdd.push(emptyRecordItem);
        currentDate = currentDate.add(1, 'day');
      }

      // APIから勤怠情報一覧を取得する
      let attendanceItems: AttendanceItem[] = [];
      try {
        const response = await AttendanceService.getListAll({
          target: {
            userIds: [userId],
          },
          term: {
            date: self.calendarYearMonth.format('YYYYMM'),
            kind: 'month',
          },
          workspaceId,
        });

        attendanceItems = self._.get(response[0], 'attendance', []);
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }

      // 勤怠データを date, regDate の昇順に並び替える
      const sortedAttendanceItems: AttendanceItem[] = self._.sortBy(attendanceItems, [
        'date',
        'regDate',
      ]);

      // DataTable用の配列に、勤怠情報を適用する
      self._.forEach(sortedAttendanceItems, (item) => {
        const index = self.$$dayjs(item.date, 'YYYYMMDD').date() - 1;
        if (index >= 0 && index < daysInMonth) {
          const { kind, time, ctime, reason, status, regDate, updDate } = item;
          const punchTime = {
            ctime,
            reason,
            regDate,
            status,
            time,
            updDate,
          };
          if (kind === 1) {
            self._.set(recordsToAdd[index], 'time.punchIn', punchTime);
          } else if (kind === 2) {
            self._.set(recordsToAdd[index], 'time.punchOut', punchTime);
          }
        }
      });

      self.records = recordsToAdd;

      self.setLastPunchThisMonth();
    },

    // 先月の打刻履歴を取得する（月初の退勤未打刻メッセージ判定用 #48533）
    async getPreviousAttendanceRecords() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      const recordsToAdd: RecordItem[] = [];
      const prevMonth = self.$$dayjs(self.displayDate, 'YYYY/M/D ddd').subtract(1, 'month');
      const firstDay = prevMonth.startOf('month');
      const lastDay = prevMonth.endOf('month');
      const daysInMonth = lastDay.diff(firstDay, 'days') + 1;
      let currentDate = firstDay;

      // DataTable用に日付だけの配列を用意する
      while (currentDate.isSameOrBefore(lastDay)) {
        const ymd = currentDate.format('YYYY-MM-DD');
        const emptyRecordItem = self._.defaults({ date: ymd }, self._.cloneDeep(InitialRecordItem));

        recordsToAdd.push(emptyRecordItem);
        currentDate = currentDate.add(1, 'day');
      }

      // APIから勤怠情報一覧を取得する
      let attendanceItems: AttendanceItem[] = [];
      try {
        const response = await AttendanceService.getListAll({
          target: {
            userIds: [userId],
          },
          term: {
            date: prevMonth.format('YYYYMM'),
            kind: 'month',
          },
          workspaceId,
        });

        attendanceItems = self._.get(response[0], 'attendance', []);
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }

      // 勤怠データを date, regDate の昇順に並び替える
      const sortedAttendanceItems: AttendanceItem[] = self._.sortBy(attendanceItems, [
        'date',
        'regDate',
      ]);

      // DataTable用の配列に、勤怠情報を適用する
      self._.forEach(sortedAttendanceItems, (item) => {
        const index = self.$$dayjs(item.date, 'YYYYMMDD').date() - 1;
        if (index >= 0 && index < daysInMonth) {
          const { kind, time, ctime, reason, status, regDate, updDate } = item;
          const punchTime = {
            ctime,
            reason,
            regDate,
            status,
            time,
            updDate,
          };
          if (kind === 1) {
            self._.set(recordsToAdd[index], 'time.punchIn', punchTime);
          } else if (kind === 2) {
            self._.set(recordsToAdd[index], 'time.punchOut', punchTime);
          }
        }
      });

      self.recordsPreviousMonth = recordsToAdd;
    },

    // 打刻後の表示時間を取得する
    getPunchingDateTime(resultTime: string): string {
      const self = this;

      // 経過時間の計算
      const resultTimeDayjs = self.$$dayjs(resultTime);
      const elapsed = self.$$dayjs.duration(resultTimeDayjs.diff(self.referenceDateTime));
      // 経過時間を基準日時に加算
      const newDateTime = self.referenceDateTime.add(elapsed.asSeconds(), 'second');

      // 時間を24時間超えて表示するための修正
      const newHour = newDateTime.diff(self.referenceDateTime.startOf('day'), 'hour');
      const newMinute = newDateTime.minute();
      const displayTime = `${newHour.toString().padStart(2, '0')}:${newMinute
        .toString()
        .padStart(2, '0')}`;
      // 表示用日付の計算
      const displayDate = newHour <= 23 ? resultTimeDayjs : resultTimeDayjs.subtract(1, 'day');
      return `${displayDate.format('YYYY/M/D ddd')} ${displayTime}`;
    },

    // 本営業日の日替わり日時を取得する
    getReferenceDateTime(): dayjs.Dayjs {
      const self = this;
      const [borderHour, borderMinute] = self.atdBorderTime.split(':').map(Number);
      const currentDateTime = self.$$dayjs();
      let referenceDateTime = currentDateTime.hour(borderHour).minute(borderMinute).second(0);

      if (currentDateTime.isBefore(referenceDateTime)) {
        referenceDateTime = referenceDateTime.subtract(1, 'day');
      }

      return referenceDateTime;
    },

    // 時刻表示を勤怠履歴カレンダー用の「前」「翌」の文字列を取得する
    getTimePrefix(targetDate: string, punchData: TimeDataItem): string {
      return getPrefixAttendanceTime(targetDate, punchData, this.atdBorderTime);
    },

    // 打刻履歴カレンダーのスクロール位置を初期化する
    initializeScrollbars() {
      const $el = this._.get(this.$refs.scroll, '$el');
      const displayDateDayjs = this.$$dayjs(this.displayDate, 'YYYY/M/D ddd');

      let sunday;
      if (displayDateDayjs.day() === 1) {
        sunday = displayDateDayjs.subtract(1, 'day');
      } else {
        sunday = displayDateDayjs.startOf('week');
      }

      if (sunday.month() === displayDateDayjs.month()) {
        sunday = sunday.date();
      } else {
        sunday = 1;
      }

      $el.scrollTop = this.isCurrentMonthCalendar ? (sunday - 1) * 32.67 + 1 : 0;
    },

    // 次の月に移動する
    nextMonth() {
      this.calendarYearMonth = this.calendarYearMonth.add(1, 'month').startOf('month');
      this.getAttendanceRecords();
    },

    // 前の月に移動する
    prevMonth() {
      this.calendarYearMonth = this.calendarYearMonth.subtract(1, 'month').startOf('month');
      this.getAttendanceRecords();
    },

    // 出勤打刻する
    async punchIn() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;
      const punchDate = !self.punchAsTomorrow
        ? self.referenceDateTime
        : self.referenceDateTime.add(1, 'day');
      const params = {
        date: punchDate.format('YYYYMMDD'),
        kind: ATTENDANCE_KIND[0].value,
        userId,
        workspaceId,
      };

      try {
        self.clearAttention();
        const result = await AttendanceService.clocking(params);

        self.punchingDateTime = self.getPunchingDateTime(result.time);
        self.getAttendanceRecords();

        if (self.punchAsTomorrow) {
          const date = punchDate.format('M/D');
          self.setAttention(`明日(${date})の出勤として打刻しました。`);
          self.punchAsTomorrow = false;
        }
      } catch (error: any) {
        self.$$log.error(error);
        self.setAttention(error.message);
      }
    },

    // 退勤打刻する
    async punchOut() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      // 日替わり日時
      const referenceDateTime = self.getReferenceDateTime();
      const dayEnd = referenceDateTime;
      const nextDayEnd = referenceDateTime.add(1, 'day');

      // 打刻日を取得
      // 通常は表示されている日付
      let targetDate = self.$$dayjs(self.displayDate, 'YYYY/M/D ddd').format('YYYYMMDD');
      // 昨日の勤務中かつ日替わり時刻を超えた場合、昨日付の退勤
      const isWorkingOnPreviousDay =
        self.lastPunchInAs ===
        self.$$dayjs(self.displayDate, 'YYYY/M/D ddd').subtract(1, 'day').format('YYYY-MM-DD');
      const isAfterReferenceDateTime = self.$$dayjs().isAfter(dayEnd);
      if (isWorkingOnPreviousDay && isAfterReferenceDateTime) {
        targetDate = self
          .$$dayjs(self.displayDate, 'YYYY/M/D ddd')
          .subtract(1, 'day')
          .format('YYYYMMDD');
      }
      // 明日の勤務中かつ日替わり時刻を超えていない場合、明日付の退勤
      const isWorkingOnNextDay =
        self.lastPunchInAs ===
        self.$$dayjs(self.displayDate, 'YYYY/M/D ddd').add(1, 'day').format('YYYY-MM-DD');
      const isBeforeReferenceDateTime = self.$$dayjs().isBefore(nextDayEnd);
      if (isWorkingOnNextDay && isBeforeReferenceDateTime) {
        targetDate = self
          .$$dayjs(self.displayDate, 'YYYY/M/D ddd')
          .add(1, 'day')
          .format('YYYYMMDD');
      }

      const params = {
        date: targetDate,
        kind: ATTENDANCE_KIND[1].value,
        userId,
        workspaceId,
      };

      try {
        // 日替わり時刻によるメッセージ表示判定
        const overReferenceDateTime = self.isWorkingFromPrevious && isAfterReferenceDateTime;

        self.clearAttention();
        const result = await AttendanceService.clocking(params);

        self.punchingDateTime = self.getPunchingDateTime(result.time);
        self.getAttendanceRecords();

        // 昨日の勤務中かつ日替わり時刻を超えた場合
        if (overReferenceDateTime) {
          self.setAttention('日替わり時刻を超えたため「退勤」の打刻は前日付けで登録されました。');
        }
      } catch (error: any) {
        self.$$log.error(error);
        self.setAttention(error.message);
      }
    },

    // 注意メッセージを設定する
    setAttention(message: string) {
      this.attention = message;
    },

    // 初期表示警告文をセットする
    setInitialAttention() {
      const self = this;
      self.clearAttention();

      // 翌日の日替わり時刻
      const referenceDateTime = self.getReferenceDateTime();

      // 昨日の勤務中かつ日替わり時刻を超え、前回出勤打刻から勤務時間上限を超えていない場合
      const isAfterReferenceDateTime = self.$$dayjs().isAfter(referenceDateTime);
      if (
        // 勤務中かどうかをisWorkingFromPreviousのなかでステータスを見て判断しているが、ステータス変更が正常でないため、退勤打刻がないことも条件に追加※直ったら消す
        self.isPunchOutPending &&
        self.isWorkingFromPrevious &&
        isAfterReferenceDateTime &&
        !self.isWorkTimeLimitExceeded
      ) {
        self.setAttention('日替わり時刻を超えたため「退勤」の打刻は前日付けになります。');
      }

      // 退勤打刻がなく、前回出勤打刻から勤務時間上限を超えた場合
      if (self.isPunchOutPending && self.isWorkTimeLimitExceeded) {
        const lastPunchInTimeFormatted = self.$$dayjs(self.lastPunchIn).format('YYYY/M/D HH:mm');
        self.setAttention(
          `前回（${lastPunchInTimeFormatted}）の「出勤」のあと「退勤」の打刻がありません。`
        );
      }
    },

    // 先月～今月の最終出勤打刻日時と最終退勤打刻日時を取得する
    setLastPunchThisMonth() {
      const now = this.$$dayjs(this.displayDate).startOf('month');
      const isCurrentMonth = this.calendarYearMonth.isSame(now, 'month');
      if (!isCurrentMonth) {
        return;
      }

      let lastPunchIn = '';
      let lastPunchInAs = '';
      let lastPunchOut = '';
      let lastPunchOutAs = '';

      // 先月分（#48533）
      this.recordsPreviousMonth.forEach((record: RecordItem) => {
        const punchIn =
          record.time.punchIn.status !== -1
            ? record.time.punchIn.ctime ?? record.time.punchIn.time
            : '';
        if (punchIn && (!lastPunchIn || punchIn > lastPunchIn)) {
          lastPunchIn = punchIn;
          lastPunchInAs = record.date;
          lastPunchOut = '';
          lastPunchOutAs = '';
        }

        const punchOut =
          record.time.punchOut.status !== -1
            ? record.time.punchOut.ctime ?? record.time.punchOut.time
            : '';
        if (punchOut && (!lastPunchOut || punchOut > lastPunchOut)) {
          lastPunchOut = punchOut;
          lastPunchOutAs = record.date;
        }
      });

      // 今月分
      this.records.forEach((record: RecordItem) => {
        const punchIn =
          record.time.punchIn.status !== -1
            ? record.time.punchIn.ctime ?? record.time.punchIn.time
            : '';
        if (punchIn && (!lastPunchIn || punchIn > lastPunchIn)) {
          lastPunchIn = punchIn;
          lastPunchInAs = record.date;
          lastPunchOut = '';
          lastPunchOutAs = '';
        }

        const punchOut =
          record.time.punchOut.status !== -1
            ? record.time.punchOut.ctime ?? record.time.punchOut.time
            : '';
        if (punchOut && (!lastPunchOut || punchOut > lastPunchOut)) {
          lastPunchOut = punchOut;
          lastPunchOutAs = record.date;
        }
      });

      this.lastPunchIn = lastPunchIn;
      this.lastPunchOut = lastPunchOut;
      this.lastPunchInAs = lastPunchInAs;
      this.lastPunchOutAs = lastPunchOutAs;
    },

    // 詳細(修正)ポップアップを開く
    async showDetail(item: RecordItem) {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      try {
        const response = await AttendanceService.getListAll({
          target: {
            userIds: [userId],
          },
          term: {
            date: self.calendarYearMonth.format('YYYYMM'),
            kind: 'month',
          },
          workspaceId,
        });

        const list = self._.get(response[0], 'attendance', []);
        const date = self.$$dayjs(item.date).format('YYYYMMDD');
        const filteredPunchIn = self._.filter(list, { date, kind: 1 });
        const filteredPunchOut = self._.filter(list, { date, kind: 2 });
        const transformedPunchIn = self.transformEditingData(filteredPunchIn);
        const transformedPunchOut = self.transformEditingData(filteredPunchOut);
        const editingItem: EditingData = {
          date: item.date,
          time: {
            punchIn: {
              // EditingDataItem定義順
              /* eslint-disable vue/sort-keys */
              origin: self._.get(transformedPunchIn, 'origin', ''),
              newest: self._.get(transformedPunchIn, 'newest', ''),
              status: self._.get(transformedPunchIn, 'status'),
              reason: self._.get(transformedPunchIn, 'reason', ''),
              oldestRegDate: self._.get(transformedPunchIn, 'oldestRegDate', ''),
              newestUpdDate: self._.get(transformedPunchIn, 'newestUpdDate', ''),
              /* eslint-enable vue/sort-keys */
            },
            punchOut: {
              // EditingDataItem定義順
              /* eslint-disable vue/sort-keys */
              origin: self._.get(transformedPunchOut, 'origin', ''),
              newest: self._.get(transformedPunchOut, 'newest', ''),
              status: self._.get(transformedPunchOut, 'status'),
              reason: self._.get(transformedPunchOut, 'reason', ''),
              oldestRegDate: self._.get(transformedPunchOut, 'oldestRegDate', ''),
              newestUpdDate: self._.get(transformedPunchOut, 'newestUpdDate', ''),
              /* eslint-enable vue/sort-keys */
            },
          },
        };

        self.setEditingItem({ item: editingItem });
        self.showAttendanceEditPopup();
      } catch (error: any) {
        self.$$log.error(error);
        self.setAttention(error.message);
      }
    },

    timeFormatted(targetDate: string, punchData: TimeDataItem): string {
      if (punchData.status === -1) {
        return '―';
      }
      if (!this._.isEmpty(punchData.ctime) && !this._.isUndefined(punchData.ctime)) {
        return getCustomFormatAttendanceTime(targetDate, punchData.ctime, this.atdBorderTime);
      }
      if (!this._.isEmpty(punchData.time)) {
        return getCustomFormatAttendanceTime(targetDate, punchData.time, this.atdBorderTime);
      }

      return '―';
    },

    // 修正ポップアップに渡す打刻データ形式に変換する
    transformEditingData(filteredData: AttendanceItem[]): EditingDataItem {
      if (filteredData.length < 1) {
        return {
          // EditingDataItem定義順
          /* eslint-disable vue/sort-keys */
          origin: '',
          newest: '',
          status: 0,
          reason: '',
          oldestRegDate: '',
          newestUpdDate: '',
          /* eslint-enable vue/sort-keys */
        };
      }

      const self = this;
      const oldest = self._.minBy(filteredData, 'regDate') as AttendanceItem;
      const newest = self._.maxBy(filteredData, (item) => {
        const latestDate = item.updDate || item.regDate;
        return new Date(latestDate).getTime();
      }) as AttendanceItem;

      // oldestとnewestが同じ（filteredDataが1件）場合、
      // 打刻→削除 または 未打刻→修正 しかない ので
      let originTime;
      let newestTime;
      if (self._.isEqual(oldest, newest)) {
        originTime = oldest.time;
        newestTime = oldest.ctime;
      } else {
        originTime = filteredData.length > 1 ? oldest.time : '' || oldest.ctime;
        newestTime = filteredData.length > 1 ? newest.ctime || newest.time : '';
      }

      return {
        // EditingDataItem定義順
        /* eslint-disable vue/sort-keys */
        origin: originTime,
        newest: newestTime,
        status: newest ? newest.status : oldest.status,
        reason: newest?.reason || '',
        oldestRegDate: oldest.regDate,
        newestUpdDate: newest?.updDate || oldest.updDate || '',
        /* eslint-enable vue/sort-keys */
      };
    },

    // 現在日時を更新する
    updateCurrentDateTime() {
      const self = this;

      // 勤怠ポップアップを閉じているときの不必要なリソース消費を防ぐ
      if (!self._.isEmpty(self.displayDate) && !self.showedAttendanceBookPopup) {
        return;
      }

      // 現在日時の設定
      const currentDateTime = self.$$dayjs();

      // 基準日時の取得
      const referenceDateTime = self.getReferenceDateTime();

      // 経過時間の計算
      const elapsed = self.$$dayjs.duration(currentDateTime.diff(referenceDateTime));

      // 経過時間を基準日時に加算
      const newDateTime = referenceDateTime.add(elapsed.asSeconds(), 'second');

      // 時間を24時間超えて表示するための修正
      const newHour = newDateTime.diff(referenceDateTime.startOf('day'), 'hour');
      const newMinute = newDateTime.minute();
      const displayTime = `${newHour.toString().padStart(2, '0')}:${newMinute
        .toString()
        .padStart(2, '0')}`;

      // 表示用日付の計算
      const displayDate = newHour <= 23 ? currentDateTime : currentDateTime.subtract(1, 'day');

      self.referenceDateTime = referenceDateTime;
      self.currentDate = currentDateTime.format('YYYY/M/D ddd');
      self.currentTime = currentDateTime.format('HH:mm');
      self.displayDate = displayDate.format('YYYY/M/D ddd');
      self.displayTime = displayTime;
      self.nextDate = displayDate.add(1, 'day').format('M/D');

      // 結果表示
      // console.log('-----');
      // console.log('日替わり時刻: ', self.atdBorderTime);
      // console.log('現在日時: ', currentDateTime.format('YYYY-MM-DD HH:mm:ss'));
      // console.log('基準日時: ', referenceDateTime.format('YYYY-MM-DD HH:mm:ss'));
      // console.log('終了日時: ', endDateTime.format('YYYY-MM-DD HH:mm:ss'));
      // console.log(
      //   '経過時間: ',
      //   `${elapsed.hours().toString().padStart(2, '0')}:${elapsed
      //     .minutes()
      //     .toString()
      //     .padStart(2, '0')}:${elapsed.seconds().toString().padStart(2, '0')}`
      // ); // 経過時間をHH:mm:ss形式で表示
      // console.log('表示用日付: ', displayDate.format('YYYY/M/D ddd'));
      // console.log('表示用時間: ', displayTime);
    },
  },
});
