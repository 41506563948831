


















































import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import ChatService from '@/services/ui/ChatService';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UIChatMapper } from '@/store/modules/ui/chat';
import { UICommonMapper } from '@/store/modules/ui/common';
import type { Phrase } from '@/store/modules/ui/chat';

const PhraseService = ServiceFactory.get('phrase');

export default Vue.extend({
  name: 'QuickChatReplyPopup',

  data(): {
    chatText: string;
    preparedQuickChatMessages: Phrase[];
    selectedMessage: string;
  } {
    return {
      chatText: '',
      preparedQuickChatMessages: [],
      selectedMessage: '',
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UIChatMapper.mapState(['phraseList', 'quickChatReplyUserId', 'quickChatReplyChatId']),
    ...UICommonMapper.mapState(['showedQuickChatListPopup', 'showedQuickChatReplyPopup']),
  },

  watch: {
    showedQuickChatReplyPopup: {
      handler() {
        if (this.showedQuickChatReplyPopup) {
          this.chatText = '';
          this.$v.$reset();
          this.loadQuickChatPhrases();
        }
      },
    },
  },

  created() {
    this.loadQuickChatPhrases();
  },

  methods: {
    ...UIChatMapper.mapActions(['setPhraseList']),
    ...UICommonMapper.mapActions(['setMessage', 'hideQuickChatReplyPopup']),

    // 選択メッセージのactive-classを有効にする
    itemSelected(phraseId: string): boolean {
      return phraseId === this.selectedMessage;
    },

    async loadQuickChatPhrases() {
      const self = this;
      const { workspaceId } = self.userAttributes;
      const responseMessageList = await PhraseService.getPhraseList(workspaceId);
      self.setPhraseList({ list: responseMessageList });
      self.preparedQuickChatMessages = self._.filter(self.phraseList, { kind: 'reply' });
    },

    selectMessage(phraseId: string) {
      const self = this;
      this.selectedMessage = phraseId;

      self.chatText = self._.get(
        self._.find(self.preparedQuickChatMessages, { phraseId }),
        'text',
        ''
      );
    },

    async sendQuickChat() {
      const self = this;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      const { workspaceId, userId } = self.userAttributes;

      try {
        await ChatService.sendFreeChat({
          memberId: self.quickChatReplyUserId,
          message: this.chatText,
          replyId: self.quickChatReplyChatId,
          userId,
          workspaceId,
        });

        self.$emit('send-quick-chat', true, self.quickChatReplyUserId);
      } catch (error: any) {
        self.$emit('send-quick-chat', false, self.quickChatReplyUserId);
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }

      self.hideQuickChatReplyPopup();
      self.selectedMessage = '';
    },
  },

  validations() {
    return {
      chatText: {
        // 表示順序で記述
        /* eslint-disable vue/sort-keys */
        required,
        maxLength: maxLength(128),
        /* eslint-enable vue/sort-keys */
      },
    };
  },
});
