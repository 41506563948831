




import Vue from 'vue';

export default Vue.extend({
  name: 'PopupBackgroundLayer',

  methods: {
    clickBackgroundLayer() {
      this.$emit('click-background-layer');
    },
  },
});
