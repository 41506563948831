


































import { mdiHelp } from '@mdi/js';
import Vue from 'vue';
import settings from '@/settings';

export default Vue.extend({
  name: 'TheHelpButton',

  inheritAttrs: false,

  data() {
    return {
      icons: {
        mdiHelp,
      },
    };
  },

  computed: {
    helpButtonItems():
      | {
          icon: string;
          title: string;
          url: string;
        }[]
      | [] {
      const { name } = this.$route;

      if (this._.isString(name) && name.length > 0) {
        return settings.helpButtonItems[name] ?? [];
      }

      return [];
    },
  },

  methods: {
    openExternalLinkInNewTab(url: string) {
      window.open(url);
    },
  },
});
