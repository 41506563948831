



























import Vue from 'vue';
import RelationEllipse from '@/components/specific/RelationEllipse.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { InitialSearchConditions } from '@/store/modules/ui/memberFilter';
import { UIRelationshipMapper } from '@/store/modules/ui/relationship';
import type { PartialMember } from '@/store/modules/ui/relationship';

const SearchService = ServiceFactory.get('search');
const RelationshipService = ServiceFactory.get('relationship');

export default Vue.extend({
  name: 'MemberCircle',

  components: {
    RelationEllipse,
  },

  data(): {
    centerMember: PartialMember;
    zoomRatio: number;
  } {
    return {
      centerMember: {},
      zoomRatio: 1,
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['memberCircleUserId', 'showedMemberCircle', 'zoom']),
  },

  watch: {
    memberCircleUserId: {
      handler() {
        this.centerMember = {};

        if (this.showedMemberCircle && !this._.isEmpty(this.memberCircleUserId)) {
          this.loadMemberRelationship();
        }
      },
    },

    showedMemberCircle: {
      handler() {
        this.centerMember = {};

        if (this.showedMemberCircle && !this._.isEmpty(this.memberCircleUserId)) {
          this.loadMemberRelationship();
        }
      },
    },

    zoom: {
      handler() {
        this.zoomRatio = this.zoom.home;
      },
      deep: true,
    },
  },

  created() {
    if (this._.isEmpty(this.memberCircleUserId)) {
      this.closeMemberCircle();
    } else {
      this.loadMemberRelationship();
    }
  },

  destroyed() {
    this.clearMemberCircleUserId();
    this.centerMember = {};
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage', 'clearMemberCircleUserId']),
    ...UIRelationshipMapper.mapActions(['setMemberRelationship']),

    closeMemberCircle() {
      this.clearMemberCircleUserId();
      this.$emit('click-close');
    },

    async loadMemberRelationship() {
      const self = this;
      const { workspaceId } = self.userAttributes;
      const userId = self.memberCircleUserId.toString();

      try {
        const procedures = [
          RelationshipService.getRelationshipSorted(workspaceId, userId),
          SearchService.memberSearch(InitialSearchConditions),
        ];
        const [resRelationship, resSearchAll] = await Promise.all(procedures);

        // 指定メンバーを中心（配列0番）にする
        const relationship = [];
        self.centerMember = self._.find(resSearchAll, { userId });
        relationship.push(self.centerMember);

        // リレーションシップ順に並べる
        const sortedMembers = resRelationship
          // APIレスポンスなのでanyを許容
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((x: any) => resSearchAll.find((v: any) => v.userId === x.userId))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((e: any) => e);

        self._.forEach(sortedMembers, (member) => {
          if (member.userId !== userId) {
            relationship.push(member);
          }
        });

        self.setMemberRelationship({ relationship });
      } catch (error) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },
  },
});
