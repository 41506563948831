import _ from 'lodash';

// 表示上の一日の終了時刻を取得する
const getEndOfDayTimeDisplay = (atdBorderTime: string) => {
  const addedTime = '23:59';
  const [baseHours, baseMinutes] = atdBorderTime.split(':').map(Number);
  const [addedHours, addedMinutes] = addedTime.split(':').map(Number);

  let resultHours = baseHours + addedHours;
  let resultMinutes = baseMinutes + addedMinutes;

  if (resultMinutes >= 60) {
    resultHours += Math.floor(resultMinutes / 60);
    resultMinutes %= 60;
  }

  const formattedHours = String(resultHours).padStart(2, '0');
  const formattedMinutes = String(resultMinutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export default getEndOfDayTimeDisplay;
