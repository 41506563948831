




































import Vue from 'vue';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';

export default Vue.extend({
  name: 'WorkspacePopup',

  computed: {
    ...UIWorkspaceMapper.mapState(['workspace']),

    workspaceImageUrl(): string {
      return this._.get(this.workspace, 'image');
    },
  },

  methods: {
    closePopup() {
      this.$emit('click-close');
    },
  },
});
