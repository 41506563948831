import { render, staticRenderFns } from "./QuickChatPopup.vue?vue&type=template&id=b12ed00c&scoped=true&"
import script from "./QuickChatPopup.vue?vue&type=script&lang=ts&"
export * from "./QuickChatPopup.vue?vue&type=script&lang=ts&"
import style0 from "./QuickChatPopup.vue?vue&type=style&index=0&id=b12ed00c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b12ed00c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VImg,VList,VListItem,VListItemContent,VTextField})
