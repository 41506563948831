import dayjs from 'dayjs';
import _ from 'lodash';

// 時刻表示を勤怠履歴カレンダー用にフォーマットした文字列を取得する
const getCustomFormatAttendanceTime = (
  punchDate: string,
  resultTime: string,
  atdBorderTime: string
) => {
  // punchDate の 日替わり日時 を取得
  const referenceDateTime = dayjs(`${punchDate} ${atdBorderTime}`, 'YYYY-MM-DD HH:mm');
  const resultTimeDayjs = dayjs(resultTime);
  const nextReferenceDateTime = referenceDateTime.add(24, 'hour');
  const prevReferenceDateTime = referenceDateTime.subtract(24, 'hour');
  // 打刻日時の日付がnextReferenceDateTimeの日付より後の場合
  if (resultTimeDayjs.isAfter(nextReferenceDateTime, 'day')) {
    const adjustedHour = resultTimeDayjs.hour() + 24;
    return `${adjustedHour}:${resultTimeDayjs.format('mm')}`;
  }
  // 打刻日時がnextReferenceDateTimeの後か、同じ場合
  if (
    resultTimeDayjs.isAfter(nextReferenceDateTime) ||
    resultTimeDayjs.isSame(nextReferenceDateTime)
  ) {
    return resultTimeDayjs.format('HH:mm');
  }

  // // 打刻日時がprevReferenceDateTimeと同じ場合
  if (resultTimeDayjs.isSame(prevReferenceDateTime)) {
    return resultTimeDayjs.format('HH:mm');
  }

  // 打刻日時がpunchDateと同日で、かつreferenceDateTimeより前の場合
  if (resultTimeDayjs.isSame(punchDate, 'day') && resultTimeDayjs.isBefore(referenceDateTime)) {
    const adjustedHour = resultTimeDayjs.hour() + 24;
    return `${adjustedHour}:${resultTimeDayjs.format('mm')}`;
  }
  // 打刻日時がreferenceDateTimeの24時間前以降で、翌0時より前の場合
  if (resultTimeDayjs.isAfter(prevReferenceDateTime) && resultTimeDayjs.isBefore(punchDate)) {
    return resultTimeDayjs.format('HH:mm');
  }
  // 打刻日時が0時以降の場合
  if (
    !resultTimeDayjs.isSame(punchDate, 'day') &&
    resultTimeDayjs.isBefore(nextReferenceDateTime)
  ) {
    const adjustedHour = resultTimeDayjs.hour() + 24;
    return `${adjustedHour}:${resultTimeDayjs.format('mm')}`;
  }
  // 同じ日でreferenceDateTime以降の場合
  return resultTimeDayjs.format('HH:mm');
};

export default getCustomFormatAttendanceTime;
