

























import Vue from 'vue';
import IconCloseMini from '@/components/specific/IconCloseMini.vue';
import Log from '@/resources/plugins/Logger/Log';
import ChatService from '@/services/ui/ChatService';
import store from '@/store';
import { DomainAuth } from '@/store/modules/domain/auth';
import { UIChat } from '@/store/modules/ui/chat';
import { UICommon } from '@/store/modules/ui/common';

export default Vue.extend({
  name: 'QuickChatNotification',

  components: {
    IconCloseMini,
  },

  props: {
    chatId: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    regDate: {
      type: String,
      default: '',
    },
    selected: Boolean,
    userId: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      default: '',
    },
  },

  data(): {
    colored: boolean;
    id: string;
    elapsedMinutes: number;
    intervalId: number | undefined;
    showedCloseButton: boolean;
  } {
    return {
      colored: false,
      elapsedMinutes: 0,
      id: '',
      intervalId: undefined,
      showedCloseButton: false,
    };
  },

  watch: {
    selected: {
      handler() {
        this.colored = this.selected;
      },
    },
  },

  mounted() {
    this.intervalId = window.setInterval(this.updateElapsedTime, 1000);
    this.id = this.chatId;
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    closeMessage() {
      const uiCommonContext = UICommon.context(store);
      const { hideQuickChatReplyPopup } = uiCommonContext.actions;

      this.$emit('close-toast');
      hideQuickChatReplyPopup();
    },

    hideCloseButton() {
      this.showedCloseButton = false;
    },

    showCloseButton() {
      this.showedCloseButton = true;
    },

    async showReplyPopup() {
      const uiChatContext = UIChat.context(store);
      const {
        clearQuickchatReplyInfo,
        setQuickchatReplyInfo,
        setQuickChatRead,
      } = uiChatContext.actions;

      const uiCommonContext = UICommon.context(store);
      const {
        setMessage,
        showQuickChatReplyPopup,
        hideQuickChatReplyPopup,
      } = uiCommonContext.actions;

      const domainAuthContext = DomainAuth.context(store);
      const { userAttributes } = domainAuthContext.state;
      const { workspaceId, userId } = userAttributes;

      this.$emit('click');
      this.colored = !this.colored;
      if (this.colored) {
        try {
          await ChatService.readChat(workspaceId, userId, { chatId: this.id });
          setQuickChatRead({ chatId: this.id });
        } catch (error) {
          Log.error(error);
          setMessage({ color: 'error', text: error.message });
        }
        setQuickchatReplyInfo({ chatId: this.id, userId: this.userId });
        showQuickChatReplyPopup();
      } else {
        clearQuickchatReplyInfo();
        hideQuickChatReplyPopup();
      }
    },

    updateElapsedTime() {
      const to = this.$$dayjs();
      const from = this.$$dayjs(this.regDate);
      this.elapsedMinutes = to.diff(from, 'minute');
    },
  },
});
