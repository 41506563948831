




















































































































































































































import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import { MESSAGE_UPDATE_ATTENDANCE_DONE } from '@/resources/defines';
import getCustomFormatAttendanceTime from '@/resources/functions/getCustomFormatAttendanceTime';
import getEndOfDayTimeDisplay from '@/resources/functions/getEndOfDayTimeDisplay';
import getPrefixAttendanceTime from '@/resources/functions/getPrefixAttendanceTime';
import ServiceFactory from '@/services/ui/ServiceFactory';
import settings from '@/settings';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UIAttendanceMapper } from '@/store/modules/ui/attendance';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';
import type { EditingDataItem } from '@/store/modules/ui/attendance';

const AttendanceService = ServiceFactory.get('attendance');

export default Vue.extend({
  name: 'AttendanceEditPopup',

  data(): {
    attention: string;
    isWritingMethodOpen: boolean;
    kind: {
      text: string;
      value: number;
    }[];
    selectedKind: number;
    updateParams: {
      reason: string;
      time: string; // [*]2200
    };
  } {
    return {
      attention: '',
      isWritingMethodOpen: true,
      kind: [
        {
          text: '出勤',
          value: 1,
        },
        {
          text: '退勤',
          value: 2,
        },
      ],
      selectedKind: 1,
      updateParams: {
        reason: '',
        time: '',
      },
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UIAttendanceMapper.mapState(['editingItem']),
    ...UICommonMapper.mapState(['showedAttendanceEditPopup']),
    ...UIWorkspaceMapper.mapState(['workspace']),

    // 日替わり設定時刻（一日の開始時刻）
    atdBorderTime(): string {
      return this._.get(this.workspace, 'atdBorderTime', settings.attendance.atdBorderTime);
    },

    displayedAttention(): boolean {
      return !this._.isEmpty(this.attention);
    },

    editingItemDate(): string {
      return !this._.isEmpty(this.editingItem.date)
        ? this.$$dayjs(this.editingItem.date).format('YYYY年M月D日(ddd)')
        : '';
    },

    // 表示上の一日の終了時刻
    endOfDayTimeDisplay(): string {
      return getEndOfDayTimeDisplay(this.atdBorderTime);
    },

    // 出勤の修正履歴があるかどうか
    punchInEditExists(): boolean {
      const reason = this._.get(this.editingItem, 'time.punchIn.reason', '');
      return !this._.isEmpty(reason);
    },

    // 退勤の修正履歴があるかどうか
    punchOutEditExists(): boolean {
      const reason = this._.get(this.editingItem, 'time.punchOut.reason', '');
      return !this._.isEmpty(reason);
    },
  },

  watch: {
    editingItem: {
      handler() {
        this.resetPopup();
      },
      deep: true,
    },

    showedAttendanceEditPopup: {
      handler() {
        if (this.showedAttendanceEditPopup) {
          this.resetPopup();
        }
      },
    },
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage']),
    ...UIAttendanceMapper.mapActions(['clearEditingItem', 'setUpdateComplete']),

    clearAttention() {
      this.attention = '';
    },

    closePopup() {
      this.$emit('close-popup');
    },

    // 更新用日時文字列(YYYY-MM-DD HH:mm:ss)を取得する
    getModifiedDateTime(date: string, time: string): string {
      const self = this;

      let modifiedTime = time;
      let modifiedDate = date;

      // 前営業日付けマーク(先頭に*)が付けられていたら、*を消す
      if (time[0] === '*') {
        modifiedTime = modifiedTime.substring(1);
      }

      const minutes = modifiedTime.slice(2);

      let hours = parseInt(modifiedTime.slice(0, 2), 10);
      while (hours >= 24) {
        // 24時を超えていたら(28:00等)、時刻から24時間を引き、翌日の日付にする
        hours -= 24;
        modifiedDate = self.$$dayjs(modifiedDate).add(1, 'day').format('YYYY-MM-DD');
      }

      // 時分をHH:mmにフォーマットする
      const formattedTime = `${String(hours).padStart(2, '0')}:${minutes}`;

      return `${modifiedDate} ${formattedTime}:00`;
    },

    // 時刻表示を勤怠履歴カレンダー用の「前」「翌」の文字列を取得する
    getTimePrefix(targetDate: string, punchTime: string): string {
      const self = this;

      // targetDate と atdBorderTime を使って referenceDateTime を作成
      const referenceDateTime = self.$$dayjs(
        `${targetDate} ${self.atdBorderTime}`,
        'YYYY-MM-DD HH:mm'
      );

      // targetDateTime を dayjs オブジェクトに変換
      if (self._.isEmpty(punchTime) || self._.isUndefined(punchTime)) {
        return '';
      }
      const targetDateTimeDayjs = self.$$dayjs(punchTime, 'YYYY-MM-DD HH:mm:ss');

      // referenceDateTime の前日と翌日、翌々日を取得
      const referenceDateTimeDayBefore = self.$$dayjs(referenceDateTime).subtract(1, 'day');
      const referenceDateTimeNext = self.$$dayjs(referenceDateTime).add(1, 'day');
      const referenceDateTimeNext2 = self.$$dayjs(referenceDateTime).add(2, 'day');

      // punchTime が referenceDateTimeDayBefore以降 かつ referenceDateTimeより前 の場合、'前' を返す
      if (
        targetDateTimeDayjs.isSameOrAfter(referenceDateTimeDayBefore) &&
        targetDateTimeDayjs.isBefore(referenceDateTime)
      ) {
        return '前';
      }

      // punchTime が referenceDateTimeNext以降 かつ referenceDateTimeNext2より前 の場合、'翌' を返す
      if (
        targetDateTimeDayjs.isSameOrAfter(referenceDateTimeNext) &&
        targetDateTimeDayjs.isBefore(referenceDateTimeNext2)
      ) {
        return '翌';
      }

      // それ以外は '' （空）を返す
      return '';
    },

    getTimePrefixAfter(targetDate: string, punchData: EditingDataItem) {
      if (!punchData || !punchData.newest || punchData.status === -1) {
        return '';
      }

      return this.getTimePrefix(targetDate, punchData.newest);
    },

    getTimePrefixBefore(targetDate: string, punchData: EditingDataItem) {
      if (!punchData || !punchData.origin || punchData.status === -1) {
        return '';
      }

      return this.getTimePrefix(targetDate, punchData.origin);
    },

    handleScroll() {
      // スクロールが始まったら、開いているVSelectは閉じる
      (this.$refs.select as any).blur();
    },

    // 修正履歴(修正後)表示用の時刻文字列を取得する
    modifiedTimeFormattedAfter(timeData: EditingDataItem): string {
      // 削除の場合は、--- (削除済) を表示する
      if (timeData.status === -1) {
        return '--- (削除済)';
      }

      return getCustomFormatAttendanceTime(
        this.editingItem.date,
        timeData.newest,
        this.atdBorderTime
      );
    },

    // 修正履歴(修正前)表示用の時刻文字列を取得する
    modifiedTimeFormattedBefore(timeData: EditingDataItem): string {
      // 無打刻の場合は、--- を表示する
      if (this._.isEmpty(timeData.origin)) {
        return '---';
      }

      return getCustomFormatAttendanceTime(
        this.editingItem.date,
        timeData.origin,
        this.atdBorderTime
      );
    },

    resetPopup() {
      this.$v.$reset();
      this.clearAttention();
      this.updateParams.time = '';
      this.updateParams.reason = '';
      this.selectedKind = 1;

      const $el = this._.get(this.$refs.scroll, '$el');
      $el.scrollTop = 0;
    },

    setAttention(message: string) {
      this.attention = message;

      // エラーメッセージがあるときはトップにスクロール
      const $el = this._.get(this.$refs.scroll, '$el');
      $el.scrollTop = 0;
    },

    toggleWritingMethod() {
      this.isWritingMethodOpen = !this.isWritingMethodOpen;
    },

    async updateAttendance() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      self.updateParams.time = self._.trim(self.updateParams.time, ' 　');
      self.updateParams.reason = self._.trim(self.updateParams.reason, ' 　');

      self.clearAttention();
      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      try {
        if (self._.isEmpty(self.updateParams.time)) {
          await AttendanceService.deleting(
            workspaceId,
            userId,
            self.$$dayjs(self.editingItem.date).format('YYYYMMDD'),
            self.selectedKind,
            {
              reason: self.updateParams.reason,
            }
          );
        } else {
          const params = { ...self.updateParams };
          const previousDay = params.time[0] === '*';
          const modifiedDate = !previousDay
            ? self.editingItem.date
            : self.$$dayjs(self.editingItem.date).subtract(1, 'day').format('YYYY-MM-DD');

          params.time = self.getModifiedDateTime(modifiedDate, params.time);

          await AttendanceService.correctingTime(
            workspaceId,
            userId,
            self.$$dayjs(self.editingItem.date).format('YYYYMMDD'),
            self.selectedKind,
            params
          );
        }

        self.clearEditingItem();
        self.setMessage({
          color: 'success',
          text: MESSAGE_UPDATE_ATTENDANCE_DONE,
        });
        self.setUpdateComplete();
        self.closePopup();
      } catch (error: any) {
        self.$$log.error(error);
        self.setAttention(error.message);
      }
    },
  },

  validations() {
    return {
      updateParams: {
        reason: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          maxLength: maxLength(128),
          /* eslint-enable vue/sort-keys */
        },
        time: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          attendanceTime: this.$$validators.attendanceTime(
            this.atdBorderTime.replace(/:/g, ''),
            this.endOfDayTimeDisplay.replace(/:/g, '')
          ),
          /* eslint-enable vue/sort-keys */
        },
      },
    };
  },
});
