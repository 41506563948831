














































































import Vue from 'vue';
import BaseButton from '@/components/generic/BaseButton.vue';
import settings from '@/settings';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIMemberFilterMapper } from '@/store/modules/ui/memberFilter';
import type { FilterItem, FilterConditionPayload } from '@/store/modules/ui/memberFilter';

export type PopupMemberDepartment = {
  departmentId: string;
  departmentName: string;
  leader: boolean;
};
export type PopupMemberProject = {
  projectId: string;
  projectName: string;
};
export type PopupMemberVoiceXs = {
  phoneNo: string;
  phoneType: string;
};
export type PopupMember = {
  userId: string;
  userName: string;
  image: string;
  departments: PopupMemberDepartment[];
  projects: PopupMemberProject[];
  voiceXs: PopupMemberVoiceXs[];
};

export default Vue.extend({
  name: 'MemberPopup',

  components: {
    BaseButton,
  },

  props: {
    member: {
      type: Object as Vue.PropType<PopupMember>,
      required: true,
    },
  },

  data(): {
    localFilterItems: {
      projects: FilterItem[];
      departments: FilterItem[];
      groups: FilterItem[];
    };
  } {
    return {
      localFilterItems: {
        departments: [],
        groups: [],
        projects: [],
      },
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['showedMemberCircle']),
    ...UIMemberFilterMapper.mapState(['filterItems']),

    className(): string {
      return this.showedMemberCircle ? 'member-circle' : '';
    },

    // 内線番号設定しているか
    hasVxNum(): boolean {
      return this.member.voiceXs.length > 0;
    },

    // 自分か
    myself(): boolean {
      return this.member.userId === this.userAttributes.userId;
    },

    showedBrowserPhoneButton(): boolean {
      return !this.myself && this.hasVxNum;
    },

    showedQuickChatButton(): boolean {
      return !this.myself;
    },

    showedUserCircleButton(): boolean {
      const self = this;

      // 自分の場合は表示しない
      if (this.myself) {
        return false;
      }

      // 自分がリーダーの部署に属していれば表示可能
      const { departments } = self.userAttributes;
      const leaderDeptIds = self._.filter(departments, { leader: 1 }).map(
        (dept) => dept.departmentId
      );
      const memberDeptIds = self.member.departments.map((dept) => dept.departmentId);
      const iAmLeader = memberDeptIds.filter((data) => leaderDeptIds.includes(data));
      if (iAmLeader.length > 0) {
        return true;
      }

      return false;
    },

    userNameShorten(): string {
      const name = this._.get(this.member, 'userName', '');

      // TODO 実際には必ず名前は入っているので、このコードはリリース前に消す
      if (this._.isEmpty(name)) {
        return '不明';
      }

      return name.substring(0, 2);
    },
  },

  methods: {
    ...UICommonMapper.mapActions(['clearPopupUserId', 'hideAllPopup', 'hidePopupBackgroundLayer']),
    ...UIMemberFilterMapper.mapActions(['setFilterItems', 'setFiltered', 'setCondition']),

    closePopup() {
      this.clearPopupUserId();
      this.$emit('click-close');
    },

    filterDepartment(item: PopupMemberDepartment) {
      const self = this;

      if (self.showedMemberCircle) {
        return;
      }

      self.localFilterItems = self._.cloneDeep(self.filterItems);

      self._.forEach(self.localFilterItems.departments, (department, index) => {
        if (!self.localFilterItems.departments[index].selected) {
          self.localFilterItems.departments[index].selected = department.id === item.departmentId;
        } else {
          // 選択済み部署をクリックしたときは解除
          self.localFilterItems.departments[index].selected = false;
        }
      });
      self._.forEach(self.localFilterItems.projects, (project, index) => {
        self.localFilterItems.projects[index].selected = false;
      });
      self._.forEach(self.localFilterItems.groups, (project, index) => {
        self.localFilterItems.groups[index].selected = false;
      });

      // 選択フィルターがなければメインプロジェクトを追加
      const selectedDepartments = self._.filter(self.localFilterItems.departments, {
        selected: true,
      });
      const mainProject = self._.find(self.localFilterItems.projects, { id: 'main' });
      if (selectedDepartments.length === 0) {
        if (mainProject) {
          mainProject.selected = true;
        }
      }

      // 選択フィルターがメインプロジェクトだけならフィルターなし扱い
      const filtered = !(selectedDepartments.length === 0 && mainProject?.selected);
      self.setFilterItems(self.localFilterItems);
      self.setFiltered({ filtered });

      const payload: FilterConditionPayload = {
        departments: {
          ids: [item.departmentId],
        },
        groups: {
          ids: [],
        },
        projects: {
          ids: [],
        },
      };
      self.setCondition(payload);
    },

    filterProject(item: PopupMemberProject) {
      const self = this;

      if (self.showedMemberCircle) {
        return;
      }

      self.localFilterItems = self._.cloneDeep(self.filterItems);

      self._.forEach(self.localFilterItems.departments, (department, index) => {
        self.localFilterItems.departments[index].selected = false;
      });
      self._.forEach(self.localFilterItems.groups, (project, index) => {
        self.localFilterItems.groups[index].selected = false;
      });
      self._.forEach(self.localFilterItems.projects, (project, index) => {
        if (!self.localFilterItems.projects[index].selected) {
          self.localFilterItems.projects[index].selected = project.id === item.projectId;
        } else {
          // 選択済みプロジェクトをクリックしたときは解除
          self.localFilterItems.projects[index].selected = false;
        }
      });

      // 選択フィルターがなければメインプロジェクトを追加
      const selectedProjects = self._.filter(self.localFilterItems.projects, { selected: true });
      const mainProject = self._.find(self.localFilterItems.projects, { id: 'main' });
      if (selectedProjects.length === 0) {
        if (mainProject) {
          mainProject.selected = true;
        }
      }

      // 選択フィルターがメインプロジェクトだけならフィルターなし扱い
      const filtered = !(selectedProjects.length === 0 && mainProject?.selected);
      self.setFilterItems(self.localFilterItems);
      self.setFiltered({ filtered });

      const payload: FilterConditionPayload = {
        departments: {
          ids: [],
        },
        groups: {
          ids: [],
        },
        projects: {
          ids: [item.projectId],
        },
      };
      self.setCondition(payload);
    },

    matchedDepartment(item: PopupMemberDepartment) {
      const selectedDepartment = this._.filter(this.filterItems.departments, {
        id: item.departmentId,
        selected: true,
      });
      return selectedDepartment.length > 0;
    },

    matchedProject(item: PopupMemberProject) {
      const selectedProject = this._.filter(this.filterItems.projects, {
        id: item.projectId,
        selected: true,
      });
      return selectedProject.length > 0;
    },

    openBrowserPhoneWindow() {
      window.open(settings.externalLinkURLs.browserPhone, '_blank');
    },

    openMemberCircle() {
      this.$emit('click-open-member-circle', { userId: this.member.userId });
    },

    openProfilePopup() {
      this.$emit('click-open-profile-popup', { userId: this.member.userId });
    },

    toggleQuickChatPopup() {
      this.$emit('click-toggle-quick-chat');
    },
  },
});
