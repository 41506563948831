





















































import Vue from 'vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'GreetingPopup',

  data(): {
    selected: number;
  } {
    return {
      selected: 0,
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['greeting']),

    day(): string {
      return this.$$dayjs().format('D');
    },

    // greetingImage(): string {
    //   return this._.sample(this.greeting.imageUrls) as string;
    // },

    // greetingText(): string {
    //   return this._.sample(this.greeting.texts) as string;
    // },

    month(): string {
      return this.$$dayjs().format('M');
    },

    weekday(): string {
      return this.$$dayjs().format('ddd');
    },

    year(): string {
      return this.$$dayjs().format('YYYY');
    },
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage']),
    ...DomainAuthMapper.mapActions(['setUserAttributesSpecified']),

    select(emo: number) {
      if (this.selected === emo) {
        this.selected = 0;
      } else {
        this.selected = emo;
      }
    },

    async updateStatus() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;
      const params = {
        emotion: this.selected,
      };

      try {
        const response = await AuthService.updateUserAttributes(workspaceId, userId, params);

        const emotion = self._.get(response, 'emotion', 0);
        self.setUserAttributesSpecified({ emotion });

        await AuthService.tokenRefresh({ userId, workspaceId });

        this.$emit('close-popup');
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },
  },
});
