

























import Vue from 'vue';

export default Vue.extend({
  name: 'WebPushRecommendPopup',

  methods: {
    closePopup() {
      this.$emit('click-close');
    },

    useWebPushNotification() {
      this.$emit('click-use-notification');
    },
  },
});
