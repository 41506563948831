









import Vue from 'vue';

export default Vue.extend({
  name: 'IconBell',

  props: {
    existsNotification: Boolean,
  },
});
