























































































import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import { MESSAGE_CHANGE_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIRelationshipMapper } from '@/store/modules/ui/relationship';
import type { PartialUserAttributes } from '@/store/modules/domain/auth';

const AuthService = ServiceFactory.get('auth');
const ImageService = ServiceFactory.get('image');

export default Vue.extend({
  name: 'EditProfilePopup',

  props: {
    attributes: {
      type: Object as Vue.PropType<PartialUserAttributes>,
      required: true,
    },
    showed: Boolean,
  },

  data(): {
    imageRemoved: boolean;
    isSelecting: boolean;
    // 初期値設定のためanyを許容
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    file: any;
    updateParams: {
      userName: string | undefined;
      kana: string | undefined;
      position: string | undefined;
      phoneNo: string | undefined;
      image?: string;
    };
    previewUrl: string;
  } {
    return {
      file: '',
      imageRemoved: false,
      isSelecting: false,
      previewUrl: '',
      updateParams: {
        kana: '',
        phoneNo: '',
        position: '',
        userName: '',
      },
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),

    previewImage(): string {
      if (this.imageRemoved) {
        return '';
      }
      return this.previewUrl ? this.previewUrl : this.userAttributes.image;
    },

    showPreviewImage(): boolean {
      return !this._.isEmpty(this.previewImage);
    },
  },

  watch: {
    showed: {
      handler() {
        if (this.showed) {
          this.previewUrl = '';
          this.updateParams.userName = this.attributes.userName;
          this.updateParams.kana = this.attributes.kana;
          this.updateParams.position = this.attributes.position;
          this.updateParams.phoneNo = this.attributes.phoneNo;
          this.imageRemoved = false;
        }
      },
    },
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage']),
    ...DomainAuthMapper.mapActions(['setUserAttributesSpecified']),
    ...UIRelationshipMapper.mapActions(['updateMember']),

    backToProfile() {
      this.$emit('close-popup');
    },

    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      const element = this.$refs.preview as HTMLInputElement;
      element.value = '';
      element.click();
    },

    removeImage() {
      this.imageRemoved = true;
    },

    showPreview() {
      const element = this.$refs.preview as HTMLInputElement;
      if (this._.isNull(element.files) || element.files.length === 0) {
        return;
      }

      // ファイルは一つ
      // eslint-disable-next-line prefer-destructuring
      this.file = element.files[0];
      this.previewUrl = URL.createObjectURL(this.file);
      this.imageRemoved = false;
    },

    async updateProfile() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.updateParams.kana = self._.trim(self.updateParams.kana, ' 　');
      self.updateParams.userName = self._.trim(self.updateParams.userName, ' 　');
      self.updateParams.position = self._.trim(self.updateParams.position, ' 　');
      const params = { ...self.updateParams };

      try {
        if (self.file) {
          const responseImage = await ImageService.uploadImage(workspaceId, 'user', self.file, {
            height: 90,
            width: 90,
          });
          params.image = responseImage.url;
        }
        if (self.imageRemoved) {
          params.image = '';
        }

        const response = await AuthService.updateUserAttributes(workspaceId, userId, params);

        self.setMessage({
          color: 'success',
          text: MESSAGE_CHANGE_DONE,
        });

        self.setUserAttributesSpecified(response);
        const update = self._.assignIn({}, response, { userId });
        self.updateMember({ member: update });

        await AuthService.tokenRefresh({ userId, workspaceId });
      } catch (error) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },
  },

  validations() {
    return {
      updateParams: {
        kana: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          maxLength: maxLength(32),
          forwardExtPhone: this.$$validators.containsOnlyFullWidthKatakanaAndSpace,
          /* eslint-enable vue/sort-keys */
        },
        phoneNo: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          forwardExtPhone: this.$$validators.forwardExtPhone,
          /* eslint-enable vue/sort-keys */
        },
        position: {
          maxLength: maxLength(32),
        },
        userName: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          maxLength: maxLength(32),
          /* eslint-enable vue/sort-keys */
        },
      },
    };
  },
});
