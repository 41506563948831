

















import Vue from 'vue';

export default Vue.extend({
  name: 'IconFilter',

  props: {
    filtered: Boolean,
  },
});
