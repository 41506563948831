










































































import Vue from 'vue';
import IconFilter from '@/components/specific/IconFilter.vue';
import IconUser from '@/components/specific/IconUser.vue';
import UserStatus from '@/components/specific/UserStatus.vue';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIMemberFilterMapper } from '@/store/modules/ui/memberFilter';
import { UIRelationshipMapper } from '@/store/modules/ui/relationship';
import type { Relationship } from '@/store/modules/ui/relationship';

export default Vue.extend({
  name: 'RelationEllipse',

  components: {
    IconFilter,
    IconUser,
    UserStatus,
  },

  props: {
    isMemberCircle: {
      type: Boolean,
      // プロジェクトデフォルト値を設定するために無効化
      // eslint-disable-next-line vue/no-boolean-default
      default: false,
    },

    propZoomRatio: {
      type: Number,
      default: 1,
    },

    useFilter: {
      type: Boolean,
      // プロジェクトデフォルト値を設定するために無効化
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },

    useMemberList: {
      type: Boolean,
      // プロジェクトデフォルト値を設定するために無効化
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },

    useZoom: {
      type: Boolean,
      // プロジェクトデフォルト値を設定するために無効化
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
  },

  data(): {
    selectedRatio: number;
    zoomRatio: {
      text: string;
      value: number;
    }[];
  } {
    return {
      selectedRatio: 1,
      zoomRatio: [
        {
          text: '100%',
          value: 1,
        },
        {
          text: '75%',
          value: 0.75,
        },
        {
          text: '50%',
          value: 0.5,
        },
        {
          text: '25%',
          value: 0.25,
        },
      ],
    };
  },

  computed: {
    ...UICommonMapper.mapState(['showedMemberCircle', 'zoom']),
    ...UIRelationshipMapper.mapState(['relationship', 'memberRelationship']),
    ...UIMemberFilterMapper.mapState(['filtered']),

    className(): string {
      return this.isMemberCircle ? 'member' : 'home';
    },

    computedRelationship(): Relationship {
      return this.isMemberCircle ? this.memberRelationship : this.relationship;
    },
  },

  watch: {
    propZoomRatio: {
      handler() {
        this.zoomChanged(this.propZoomRatio);
      },
    },

    showedMemberCircle: {
      handler() {
        if (this.showedMemberCircle) {
          this.initializeScrollbars();
        }
      },
    },
  },

  created() {
    this.selectedRatio = this.zoom.home;
  },

  mounted() {
    this.initializeScrollbars();
    this.zoomChanged(this.selectedRatio);
  },

  methods: {
    ...UICommonMapper.mapActions(['setZoom']),

    initializeScrollbars() {
      const $el = this._.get(this.$refs.scroll, '$el');
      $el.scrollLeft = ($el.scrollWidth - window.innerWidth + 34) / 2;
      $el.scrollTop = ($el.scrollHeight - window.innerHeight + 68) / 2;
    },

    openFilterPopup() {
      this.$emit('click-open-filter');
    },

    openMemberListPopup() {
      this.$emit('click-open-member-list');
    },

    // サークルの縮尺を変更する
    zoomChanged(ratio: number) {
      const ellipseContainer = this.$refs.ellipseContainer as InstanceType<
        typeof HTMLSelectElement
      >;

      if (ratio === 1) {
        ellipseContainer.style.transform = '';
      } else {
        ellipseContainer.style.transform = `scale(${ratio}, ${ratio})`;
      }
      this.setZoom({ home: ratio });
    },
  },
});
